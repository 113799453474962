

// Base styles
import colors from "assets/theme/base/colors";

// Helper Functions
import pxToRem from "assets/theme/functions/pxToRem";

const { transparent } = colors;

// types
type Types = any;

const select: Types = {
  styleOverrides: {
    select: {
      // display: "grid",
      // alignItems: "center",
      // padding: `0 ${pxToRem(12)} !important`,
      paddingRight: "18px !important",
      "& .Mui-selected": {
        backgroundColor: transparent.main,
      },
    },

    selectMenu: {
      // background: "none",
      // height: "none",
      // minHeight: "none",
      // overflow: "unset",
    },

    icon: {
      marginTop: "auto",
      marginBottom: "auto",
      top: "auto",
      right: "1.4rem",
    },
  },
};

export default select;
