import React, { useState, ReactElement, useEffect } from "react";

// components
import MDBox from "components/MDBox";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import Header from "./Header/Header";
import Step1 from "./Step1/Step1";
import Step2 from "./Step2/Step2";
import Step3 from "./Step3/Step3";

// services
import useModels from "hooks/useModels.hook";
import { styles } from "./MainPage.styles";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { useLocation } from 'react-router-dom';

// sounds
import TermsAndConditions from "./TermsAndConditions/TermsAndConditions";
import InstractionsScreen from "./InstractionsScreen/InstractionsScreen";
import TestResaults from "./TestResaults/TestResaults";
import ModalsActions from "./ModalsActions/ModalsActions";

import { useAppSelector } from "store/hooks";

import { ResaultActionType, UserActions } from "types/user";
import { checkCameraPermission } from "utils/camera.util";


type StepMap = {
  [key: number | string]: {
    Component: ReactElement;
    extraData?: any;
    contentHeight?: string;
  };
};

const MOVE_TO_CAMERA = 'TestLabCamera';



const MainPage = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [resaultAction, setResaultAction] = useState<ResaultActionType>();
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const result = useAppSelector((state) => state.imageRecognition.result);
  const { search } = useLocation();
  const { startLoadingModels } = useModels();


  useEffect(() => {
    console.log("Start camera permission verification");
    (async () => {
      try {
        await checkCameraPermission();
        if (checkMoveToCamera()) {
          console.log("going to step 3 - CAMERA")
          startLoadingModels();
          setCurrentStep(4);
        }

      } catch {}
    })();
  }, []);

  const checkMoveToCamera = () => {
    const query = new URLSearchParams(search);
    return query.get(MOVE_TO_CAMERA) === 'true';
  }
  const handleUserAction = (userAction: UserActions) => {
    if (userAction === 'WRONG_RESAULT') {
      setResaultAction('DONE');
    }
  };

  const handleUpdateStep = (step: number) => {
    setCurrentStep((prev) => {
      if (step < 0 || step > Object.keys(stepMap).length - 1) {
        return prev;
      }
      return step;
    });
  };

  const stepMap: StepMap = {
    0: {
      Component: <TermsAndConditions handleUpdateStep={handleUpdateStep} />,
      contentHeight: "calc(100vh - 270px)",
    },
    1: {
      Component: <InstractionsScreen handleUpdateStep={handleUpdateStep} />,
    },
    2: {
      Component: <Step1 handleUpdateStep={handleUpdateStep} />,
    },
    3: {
      Component: <Step2 handleUpdateStep={handleUpdateStep} />,
    },
    4: {
      Component: <Step3 handleUpdateStep={handleUpdateStep} />,
    },
    5: {
      Component: (
        <TestResaults
          handleUpdateStep={handleUpdateStep}
          activeInnerStep={result === "POSITIVE" ? 1 : 2}
          setResaultAction={setResaultAction}
        />
      ),
    },
    6: {
      Component: (
        <ModalsActions
          handleUserAction={handleUserAction}
          resaultAction={resaultAction}
        />
      ),
    },
  };

  return (
    <DashboardLayout>
      <Grid className="mainpage-grid" container mt={0} spacing={0}>
        <MDBox pb={1.5}>
          <Header currentStep={currentStep} />
          <div
            className={classNames(
              classes.bodyContainer,
              currentStep === 6 ? classes.step_MODALS : ""
            )}
            style={{ height: stepMap[currentStep].contentHeight }}
          >
            {stepMap[currentStep].Component}
          </div>
        </MDBox>
      </Grid>
    </DashboardLayout>
  );
};
export default MainPage;
