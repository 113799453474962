import * as stepLabelStatic from "assets/components-static-styles/components/stepper/stepLabel";

// types
type Types = any;

const stepLabel: Types = {
  ...stepLabelStatic.default,
};

export default stepLabel;
