import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  bodyContainer: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100% - 56px)",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  bodyTitle: {
    margin: "0px 0 10px 0",
    fontWeight: 700,
    fontSize: "18px",
  },
  bodyText: {
    padding: "0 15px",
    fontSize: "18px",
    textAlign: "center",
    lineHeight: "23px",
  },
  bodyImage: {
    marginTop: 5,
    // marginLeft: "-48px"
  },
}));
