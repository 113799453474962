// Base styles
import typography from "assets/theme/base/typography";

const { size } = typography;

// types
type Types = any;

const breadcrumbs: Types = {
  styleOverrides: {
    li: {
      lineHeight: 0,
    },

    separator: {
      fontSize: size.md,
    },
  },
};

export default breadcrumbs;
