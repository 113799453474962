// Base styles
import * as autocompleteStatic from "assets/components-static-styles/components/form/autocomplete";
import colors from "assets/theme-dark/base/colors";

// Helper Functions
import rgba from "assets/theme-dark/functions/rgba";
import { merge } from "lodash";

const { background, text, white, transparent, light, dark, gradients } = colors;

// types
type Types = any;

const themeStyles = {
  styleOverrides: {
    popper: {
      color: text.main,
      backgroundColor: `${background.card} !important`,
    },

    paper: {
      backgroundColor: transparent.main,
    },

    option: {
      color: text.main,

      "&:hover, &:focus, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus": {
        backgroundColor: rgba(light.main, 0.2),
        color: white.main,
      },

      '&[aria-selected="true"]': {
        backgroundColor: `${rgba(light.main, 0.2)} !important`,
        color: `${white.main} !important`,
      },
    },

    noOptions: {
      color: text.main,
    },

    groupLabel: {
      color: dark.main,
    },

    loading: {
      color: text.main,
    },

    tag: {
      backgroundColor: gradients.dark.state,
      color: white.main,

      "& .MuiSvgIcon-root, & .MuiSvgIcon-root:hover, & .MuiSvgIcon-root:focus": {
        color: white.main,
      },
    },

    popupIndicator: {
      color: text.main,
    },

    clearIndicator: {
      color: text.main,
    },
  },
};

const autocomplete: Types = merge(themeStyles, autocompleteStatic.default);

export default autocomplete;
