// Base styles
import * as switchButtonStatic from "assets/components-static-styles/components/form/switchButton";
import colors from "assets/theme-dark/base/colors";

// Helper functions
import { merge } from "lodash";
import linearGradient from "assets/theme-dark/functions/linearGradient";

const { white, gradients, grey, transparent } = colors;

// types
type Types = any;

const themeStyles = {
  styleOverrides: {
    switchBase: {
      color: gradients.dark.main,

      "&:hover": {
        backgroundColor: transparent.main,
      },

      "&.Mui-checked": {
        color: gradients.dark.main,

        "&:hover": {
          backgroundColor: transparent.main,
        },

        "& .MuiSwitch-thumb": {
          borderColor: `${gradients.dark.main} !important`,
        },

        "& + .MuiSwitch-track": {
          backgroundColor: `${gradients.dark.main} !important`,
          borderColor: `${gradients.dark.main} !important`,
        },
      },

      "&.Mui-focusVisible .MuiSwitch-thumb": {
        backgroundImage: linearGradient(gradients.info.main, gradients.info.state),
      },
    },

    thumb: {
      backgroundColor: white.main,
      borderColor: grey[400],
    },

    track: {
      backgroundColor: grey[400],
      borderColor: grey[400],
    },

    checked: {},
  },
};

const switchButton: Types = merge(themeStyles, switchButtonStatic.default);

export default switchButton;
