import React, { FC, useEffect, useState } from "react";

import useSound from "use-sound";
import dayjs from "dayjs";
import { t } from "i18next";

import { makeStyles } from "@mui/styles";
import { styles } from "./Timer.styles";

import timerDoneSound from "assets/sounds/timerDone.mp3";

interface TimerProps {
  timerLength: number;
  timerLocalStorageKey: string;
  setTimerDone: any;
  text: string;
}

const Timer: FC<TimerProps> = ({
  timerLength = 120,
  timerLocalStorageKey,
  setTimerDone,
  text,
}) => {
  const [timerValue, setTimerValue] = useState(timerLength);
  const [timerDone] = useSound(timerDoneSound);

  let countStartedAt = "";

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  useEffect(() => {
    if (localStorage.getItem(timerLocalStorageKey)) {
      localStorage.removeItem(timerLocalStorageKey);
    }
    countStartedAt = `${Date.now()}`; //localStorage.getItem(timerLocalStorageKey);
    localStorage.setItem(timerLocalStorageKey, countStartedAt);

    //console.log({ countStartedAt });
    startCounting();
    return () => localStorage.removeItem(timerLocalStorageKey);
  }, []);

  useEffect(() => {
    timerValue === 0 && timerDone();
  }, [timerValue]);

  const startCounting = () => {
    updateTimer();

    setTimeout(() => {
      const timePassed = updateTimer();
      if (timePassed > timerLength) {
        setTimerDone(true);
        localStorage.removeItem(timerLocalStorageKey);
        setTimerValue(0);
      } else {
        startCounting();
      }
    }, 1000);
  };

  const updateTimer = (): number => {
    const _timePassed = Math.ceil((Date.now() - Number(countStartedAt)) / 1000);
    setTimerValue((timerLength - _timePassed) * 1000);
    return _timePassed;
  };

  return (
    <div className={classes.timerContainer}>
      <div className={classes.timerText2}>{t(text)}</div>
      <div className={classes.timer}>{dayjs(timerValue).format("mm:ss")}</div>
    </div>
  );
};

export default Timer;
