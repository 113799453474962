// Base styles
import borders from "assets/theme/base/borders";
import boxShadows from "assets/theme/base/boxShadows";
import pxToRem from "assets/theme/functions/pxToRem";

const { borderRadius } = borders;
const { xxl } = boxShadows;

// types
type Types = any;

const dialog: Types = {
  styleOverrides: {
    paper: {
      borderRadius: borderRadius.xl,
      boxShadow: xxl,
      padding: pxToRem(8),
    },

    paperFullScreen: {
      borderRadius: 0,
    },
  },
};

export default dialog;
