// Base styles
import * as stepConnectorStatic from "assets/components-static-styles/components/stepper/stepConnector";
import colors from "assets/theme/base/colors";

// Helper functions
import { merge } from "lodash";

const { white } = colors;

// types
type Types = any;

const themeStyles = {
  styleOverrides: {
    root: {
      color: "#9fc9ff",

      "&.Mui-active": {
        color: white.main,
      },

      "&.Mui-completed": {
        color: white.main,
      },
    },
  },
};

const stepConnector: Types = merge(themeStyles, stepConnectorStatic.default);

export default stepConnector;
