import React, { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";

import MDButton from "components/MDButton";
import { ReactComponent as NegativeImage } from "assets/images/NegativeImage.svg";
import { ReactComponent as PositiveImage } from "assets/images/PositiveImage.svg";

import { ClassNameMap, makeStyles } from "@mui/styles";
import { styles } from "./TestResaults.styles";
import { ResaultActionType } from "types/user";

interface StepProps {
  handleUpdateStep: (step: number) => void;
  setResaultAction: (action: ResaultActionType) => void;
  activeInnerStep?: number;
}

interface ResaultComponentProps {
  classes: ClassNameMap<string>,
  image: React.ReactNode,
  title: string;
  handleUpdateStep: (step: number) => void;
  setResaultAction: (action: ResaultActionType) => void;
}

type Step1Map = {
  [key: number]: {
    Component: ReactElement;
  };
};

const ResaultComponent: FC<ResaultComponentProps> = ({
  classes,
  image,
  title,
  handleUpdateStep,
  setResaultAction,
}) => {
  const { t } = useTranslation();
  return (
    <div className={classes.resaultComponentContainer}>
      <div className={classes.resaultBody}>
        <div className={classes.image}>{image}</div>
        <div className={classes.title}>{title}</div>
        <div className={classes.text}>{t("ResaultScreen:resaultText")}</div>
      </div>
      <div className={classes.buttonsSection}>
        <div
          className={classes.link}
          onClick={() => {
            handleUpdateStep(6);
            setResaultAction("REPORT");
          }}
        >
          {t("ResaultScreen:reportLink")}
        </div>
        <MDButton
          style={{ width: "100%", height: "56px" }}
          color={"primary"}
          variant="contained"
          type="submit"
          onClick={() => {
            handleUpdateStep(6);
            setResaultAction("DONE");
          }}
        >
          {t("ResaultScreen:buttons:doneButton")}
        </MDButton>
      </div>
    </div>
  );
};

const TestResaults: FC<StepProps> = ({ handleUpdateStep, setResaultAction, activeInnerStep }) => {
  const { t } = useTranslation();
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const body: Step1Map = {
    1: {
      Component: (
        <ResaultComponent
          classes={classes}
          image={<PositiveImage />}
          title={t("ResaultScreen:resaultTitlePositive")}
          handleUpdateStep={handleUpdateStep}
          setResaultAction={setResaultAction}
        />
      ),
    },
    2: {
      Component: (
        <ResaultComponent
          classes={classes}
          image={<NegativeImage />}
          title={t("ResaultScreen:resaultTitleNegative")}
          handleUpdateStep={handleUpdateStep}
          setResaultAction={setResaultAction}
        />
      ),
    }
  };

  return <div className={classes.container}>{body[activeInnerStep].Component}</div>;
};

export default TestResaults;
