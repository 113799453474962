// Base styles
import * as staticOutlined from "assets/components-static-styles/components/button/outlined";
import { merge } from "lodash";

import colors from "assets/theme/base/colors";
const { transparent, light, info, secondary } = colors;

const themeStyles = {
  base: {
    color: light.main,
    borderColor: light.main,

    "&:hover": {
      backgroundColor: transparent.main,
    },
  },

  primary: {
    backgroundColor: transparent.main,
    borderColor: info.main,

    "&:hover": {
      backgroundColor: transparent.main,
    },
  },

  secondary: {
    backgroundColor: transparent.main,
    borderColor: secondary.main,

    "&:hover": {
      backgroundColor: transparent.main,
    },
  },
};

const mergedStyles = merge(themeStyles, staticOutlined.default);

const outlined = {
  ...mergedStyles,
};

export default outlined;
