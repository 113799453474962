// Base styles
import borders from "assets/theme/base/borders";

// Helper Functions
import pxToRem from "assets/theme/functions/pxToRem";

const { borderWidth } = borders;

// types
type Types = any;

const radio: Types = {
  styleOverrides: {
    root: {
      "& .MuiSvgIcon-root": {
        width: pxToRem(20),
        height: pxToRem(20),
        borderWidth: borderWidth[1],
        borderStyle: "solid",
        borderRadius: "50%",
      },

      "&:after": {
        transition: "opacity 250ms ease-in-out",
        content: `""`,
        position: "absolute",
        width: pxToRem(14),
        height: pxToRem(14),
        borderRadius: "50%",
        opacity: 0,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: "auto",
      },

      "&.Mui-focusVisible": {
        borderWidth: borderWidth[2],
        borderStyle: "solid",
      },
    },

    colorPrimary: {
      "&:after": {
        opacity: 1,
      },
    },

    colorSecondary: {
      "&:after": {
        opacity: 1,
      },
    },
  },
};

export default radio;
