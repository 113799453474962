import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as WarningIcon } from "assets/images/WarningIcon.svg";
import { useStyles } from "./CameraDeniedModal.styles";
import { getMobileOperatingSystem } from "utils/dom.util";
import MDButton from "components/MDButton";

interface CameraDeniedModalProps {
  tryAgain?: () => void;
}

export const CameraDeniedModal = ({ tryAgain }: CameraDeniedModalProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const platform = getMobileOperatingSystem();

  return (
    <section className={classes.modalWrapper}>
      <div className={classes.modalContent}>
        <WarningIcon className={classes.loader} />
        <div className={classes.modalTitle}>{t("CameraDeniedModal:title")}</div>
        {platform === "Android" ?
          <>
            <p className={classes.modalDescription}>{t("CameraDeniedModal:descriptionAndroid")}</p>
            <p className={classes.modalDescription}>{t("CameraDeniedModal:descriptionAndroid2")}</p>
            <div className={classes.actions}>
              <MDButton 
                color={"primary"}
                variant="contained"
                type="submit"
                className={classes.btn}
                onClick={tryAgain}
              >{t("CameraDeniedModal:ok")}</MDButton>
            </div>
          </> 
          : 
          <>
            <p className={classes.modalDescription}>{t("CameraDeniedModal:description")}</p>
            <p className={classes.modalDescription}>{t("CameraDeniedModal:description2")}</p>
          </>
          }
      </div>
    </section>
  );
}