

// Base styles
import typography from "assets/theme/base/typography";

const { size } = typography;

// types
type Types = any;

const dialogContentText: Types = {
  styleOverrides: {
    root: {
      fontSize: size.md,
    },
  },
};

export default dialogContentText;
