import { LanguageType } from "types/language";

const Languages: LanguageType[] = [
  {
    key: "English",
    label: "English",
    serverKey: "ENGLISH",
    isRtl: false,
    source: require("../../assets/locales/en.json"),
  },
  {
    key: "עברית",
    label: "עברית",
    serverKey: "HEBREW",
    isRtl: true,
    source: require("../../assets/locales/he.json"),
  },
  {
    key: "عربيه",
    label: "عربيه",
    serverKey: "ARABIC",
    isRtl: true,
    source: require("../../assets/locales/ar.json"),
  },
];

export default Languages;
