// Base styles
import * as avatarStatic from "assets/components-static-styles/components/avatar";
// types
type Types = any;

const avatar: Types = {
  ...avatarStatic.default,
};

export default avatar;
