// Base styles
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";

const { borderWidth } = borders;

// types
type Types = any;

const input: Types = {
  styleOverrides: {
    root: {
      "&:hover:not(.Mui-disabled):before": {
        borderBottomWidth: borderWidth[1],
        borderBottomStyle: "solid",
      },
    },
  },
};

export default input;
