// Base styles
import boxShadows from "assets/theme/base/boxShadows";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";

// Helper Functions
import pxToRem from "assets/theme/functions/pxToRem";

const { lg } = boxShadows;
const { size } = typography;
const { borderRadius } = borders;

// types
type Types = any;

const autocomplete: Types = {
  styleOverrides: {
    popper: {
      boxShadow: lg,
      padding: pxToRem(8),
      fontSize: size.sm,
      textAlign: "left",
      borderRadius: borderRadius.md,
    },

    paper: {
      boxShadow: "none",
    },

    option: {
      padding: `${pxToRem(4.8)} ${pxToRem(16)}`,
      borderRadius: borderRadius.md,
      fontSize: size.sm,
      transition: "background-color 300ms ease, color 300ms ease",
    },

    noOptions: {
      fontSize: size.sm,
    },

    loading: {
      fontSize: size.sm,
    },

    tag: {
      display: "flex",
      alignItems: "center",
      height: "auto",
      padding: pxToRem(4),

      "& .MuiChip-label": {
        lineHeight: 1.2,
        padding: `0 ${pxToRem(10)} 0 ${pxToRem(4)}`,
      },

      "& .MuiSvgIcon-root, & .MuiSvgIcon-root:hover, & .MuiSvgIcon-root:focus": {
        marginRight: 0,
      },
    },
  },
};

export default autocomplete;
