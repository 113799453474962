// Base styles
import * as radioStatic from "assets/components-static-styles/components/form/radio";
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Helper functions
import { merge } from "lodash";
import linearGradient from "assets/theme/functions/linearGradient";

const { borderColor } = borders;
const { transparent, info } = colors;

// types
type Types = any;

const themeStyles = {
  styleOverrides: {
    root: {
      "& .MuiSvgIcon-root": {
        color: transparent.main,
        borderColor: borderColor,
      },

      "&:after": {
        backgroundImage: linearGradient(info.main, info.main),
      },

      "&:hover": {
        backgroundColor: transparent.main,
      },

      "&.Mui-focusVisible": {
        borderColor: `${info.main} !important`,
      },
    },

    colorPrimary: {
      color: borderColor,

      "&.Mui-checked": {
        color: info.main,

        "& .MuiSvgIcon-root": {
          borderColor: info.main,
        },
      },
    },

    colorSecondary: {
      color: borderColor,

      "&.Mui-checked": {
        color: info.main,

        "& .MuiSvgIcon-root": {
          borderColor: info.main,
        },
      },
    },
  },
};

const radio: Types = merge(themeStyles, radioStatic.default);

export default radio;
