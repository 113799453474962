// Base styles
import * as selectStatic from "assets/components-static-styles/components/form/select";

// types
type Types = any;

const select: Types = {
  ...selectStatic.default,
};

export default select;
