// Base styles
import * as staticText from "assets/components-static-styles/components/button/text";

// Base styles
import colors from "assets/theme/base/colors";

// Helper Functions
import { merge } from "lodash";

const { text, black, secondary, transparent, primary } = colors;

const themeStyles = {
  base: {
    backgroundColor: transparent.main,
    color: text.main,

    "&:hover": {
      backgroundColor: transparent.main,
    },
  },

  primary: {
    color: primary.main,

    "&:hover": {
      color: primary.main,
    },

    "&:focus:not(:hover)": {
      color: primary.focus,
    },
  },

  secondary: {
    color: secondary.main,

    "&:hover": {
      color: secondary.main,
    },

    "&:focus:not(:hover)": {
      color: secondary.focus,
    },
  },
  info: {
    color: black.main,
  },
};

const mergedStyles = merge(themeStyles, staticText.default);

const buttonText = {
  ...mergedStyles,
};

export default buttonText;
