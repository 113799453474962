// Base styles
import * as linkStatic from "assets/components-static-styles/components/link";

// types
type Types = any;

const link: Types = {
  ...linkStatic.default,
};

export default link;
