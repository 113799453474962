// Base styles
import * as tableContainerStatic from "assets/components-static-styles/components/table/tableContainer";
import colors from "assets/theme/base/colors";

// Helper functions
import { merge } from "lodash";

const { white } = colors;

// types
type Types = any;

const themeStyles = {
  styleOverrides: {
    root: {
      backgroundColor: white.main,
    },
  },
};

const tableContainer: Types = merge(themeStyles, tableContainerStatic.default);

export default tableContainer;
