import { Route } from "interfaces/infra";
import { AuthData, UserRole } from "types/user";

const AUTH_DATA_KEY = "authData";

export const storeAuthData = (authData: AuthData) => {
  localStorage.setItem(AUTH_DATA_KEY, JSON.stringify(authData));
};

export const updateStoredAccessToken = (jwtToken: string) => {
  storeAuthData({ ...getStoredAuthData(), jwtToken });
}

export const getStoredAuthData = (): AuthData | undefined => {
  const data = localStorage.getItem(AUTH_DATA_KEY);
  if (data) {
    return JSON.parse(data);
  }
};

export const getStoredAccessToken = (): string | undefined => {
  return getStoredAuthData()?.jwtToken;
};

export const getStoredRefreshToken = (): string | undefined => {
  return getStoredAuthData()?.refreshToken;
};

export const removeAuthDataFromStorage = () => {
  localStorage.removeItem(AUTH_DATA_KEY);
};

export const logout = async () => {
  removeAuthDataFromStorage();
  window.location.href = window.location.origin;
};

export const filterUnAuthurizedRoutes = (_routes: Route[], userRole: UserRole) => {
  return _routes.filter((route: any) => 
    route.route && route.role.includes(userRole));
};