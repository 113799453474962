// Base styles
import * as tableHeadStatic from "assets/components-static-styles/components/table/tableHead";

// types
type Types = any;

const tableHead: Types = {
  ...tableHeadStatic.default,
};

export default tableHead;
