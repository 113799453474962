import colors from "assets/theme/base/colors";

const { text, white, transparent, light, background, primary } = colors;

interface Styles {
  [key: string]: any;
}
export const styles: Styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  bodyContainer: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100% - 56px)",
    alignItems: "flex-start",
    overflow: "auto",
  },
  youTubeContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  attentionContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    minHeight: 96,
    padding: "8px",
    marginTop: "20px",
    background: background.secondary,
    borderRadius: "28px",
  },
  detailsContainer: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    margin: "0 6px",
  },
  attentionTitle: {
    display: "flex",
    flexDirection: "column",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "23px",
  },
  attentionDescription: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18px",
  },
  iconBackground: {
    position: "relative",
    height: 64,
    width: 64,
    margin: "0 8px",
  },
  attentionIcon: {
    position: "absolute",
    top: "calc(50% - 14px)",
    right: "calc(50% - 14px)",
  },
  iframeClassName: {
    borderRadius: "20px",
  },
  content: {
    fontSize: "14px",
    fontWeight: 400,
    whiteSpace: "pre-line",
    paddingBottom: 20,
  },
};
