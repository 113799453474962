// Base styles
import * as sliderStatic from "assets/components-static-styles/components/slider";
import colors from "assets/theme-dark/base/colors";

// Helper functions
import { merge } from "lodash";

const { grey, white, black, info } = colors;

// types
type Types = any;

const themeStyles = {
  styleOverrides: {
    root: {
      "& .MuiSlider-valueLabel": {
        color: black.main,
      },
    },

    rail: {
      background: grey[200],
    },

    track: {
      background: info.main,
    },

    thumb: {
      backgroundColor: white.main,
      borderColor: info.main,
    },
  },
};

const slider: Types = merge(themeStyles, sliderStatic.default);

export default slider;
