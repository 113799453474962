// Base styles
import * as menuItemStatic from "assets/components-static-styles/components/menu/menuItem";
import colors from "assets/theme-dark/base/colors";

// Helper functions
import { merge } from "lodash";
import rgba from "assets/theme-dark/functions/rgba";

const { white, dark } = colors;

// types
type Types = any;

const themeStyles = {
  styleOverrides: {
    root: {
      color: rgba(white.main, 0.8),

      "&:focus, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus": {
        backgroundColor: dark.main,
        color: white.main,
      },

      "&:hover": {
        backgroundColor: `${dark.main}66 !important`,
        color: white.main,
      },
    },
  },
};

const menuItem: Types = merge(themeStyles, menuItemStatic.default);

export default menuItem;
