import React from "react";
import { ReactComponent as LoaderBackground } from "assets/images/LoaderBackground.svg";
import { ReactComponent as Loader } from "assets/images/Loader.svg";
import { useStyles } from "./LoaderModelModal.styles";

interface LoaderModelModalProps {
  modelLoaded: boolean;
  title: string;
  description: string;
}

export const LoaderModelModal = ({ modelLoaded, title, description  }: LoaderModelModalProps) => {
  const classes = useStyles();

  if (modelLoaded) { return null; }

  return (
    <section className={classes.modalWrapper}>
      <div className={classes.modalContent}>
        <div className={classes.loader}>
          <LoaderBackground />
          <Loader className={classes.loaderBody} />
        </div>
        <div className={classes.modalTitle}>{title}</div>
        <div className={classes.modalDescription}>{description}</div>
      </div>
    </section>
  );
}