// Base styles
import * as menuItemStatic from "assets/components-static-styles/components/menu/menuItem";
import colors from "assets/theme/base/colors";

// Helper functions
import { merge } from "lodash";
import { globalColors } from "assets/colors-global";

const { light, text, dark } = colors;

// types
type Types = any;

const themeStyles = {
  styleOverrides: {
    root: {
      color: text.main,

      "&:focus, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus": {
        backgroundColor: globalColors.secondary4.main,
        color: dark.main,
      },

      "&:hover": {
        backgroundColor: `${light.main} !important`,
        color: dark.main,
      },
    },
  },
};

const menuItem: Types = merge(themeStyles, menuItemStatic.default);

export default menuItem;
