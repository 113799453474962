import React, { useState, useEffect } from "react";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// import { authRoutes } from "routes";
import Main from "layouts/Main/Main";
import { toast, ToastContainer } from "react-toastify";
import { setDirection, useMaterialUIController } from "context";
import { useAppDispatch, useAppSelector } from "store/hooks";
import userSlice, { getAuthData, fetchUserConfig } from "store/slices/user";
import Languages from "services/i18n/languages";
import i18n from "services/i18n/i18n";
import rtlPlugin from "stylis-plugin-rtl";
import { useMemo } from "react";
import createCache from "@emotion/cache";

const queryClient = new QueryClient();

export default function App() {
  const { pathname } = useLocation();
  const [isLoggedIn, setIsLogged] = useState<boolean>(true);
  const [rtlCache, setRtlCache] = useState(null);
  const appDispatch = useAppDispatch();
  // const authData = useAppSelector(getAuthData);
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, direction } = controller;

  // Cache for the rtl
  useMemo(() => {
    const pluginRtl: any = rtlPlugin;
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [pluginRtl],
    });
    setRtlCache(cacheRtl);
  }, []);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    updateLangugeSettings();
    appDispatch(userSlice.actions.setAuthDataFromStorage());
  }, []);

  // useEffect(() => {
  //   const logged = !!(authData && authData.jwtToken);
  //   setIsLogged(logged);
  //   logged && appDispatch(fetchUserConfig());
  // }, [authData]);

  const updateLangugeSettings = () => {
    const localLanguage = localStorage.getItem("language");
    let lang = "עברית";
    if (localLanguage) {
      lang = localLanguage;
    } else {
      localStorage.setItem("language", lang);
    }
    const langDirection: "rtl" | "ltr" = Languages.find((l) => l.key === lang)?.isRtl
      ? "rtl"
      : "ltr";
    
    setDirection(dispatch, langDirection);
    i18n.changeLanguage(lang);
  };

  const renderBody = () => {
    return (
      <React.Fragment>
        <CssBaseline />
        <Routes>
          <Route path="*" element={isLoggedIn ? <Main /> : <Navigate to="/login" />} />
        </Routes>
        <ToastContainer
          theme={(darkMode && "dark") || "light"}
          position={direction === "rtl" ? toast.POSITION.TOP_LEFT : toast.POSITION.TOP_RIGHT}
          toastClassName="dark-toast"
          style={{ fontSize: "14px", fontFamily: "Roboto" }}
        />
      </React.Fragment>
    );
  };

  return typeof isLoggedIn !== "boolean" ? null : (
    <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={direction === "rtl" ? themeRTL : theme}>{renderBody()}</ThemeProvider>
    </QueryClientProvider>
  );
}
