import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Webcam from "react-webcam";

import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { RecognitionStatus } from "types/imageRecognition";
import { useStyles } from "./CameraScan.styles";
import { globalColors } from "assets/colors-global";
import { useTranslation } from "react-i18next";
import { rotateBase64Image } from "utils/dom.util";
import LinearProgress from "@mui/material/LinearProgress";
// import { images } from "./base64_images";

const statusColor: Record<RecognitionStatus, string> = {
  ERROR: globalColors.error.main,
  PROGRESS: globalColors.warning.main,
  SUCCESS: globalColors.success.main,
};

const getWindowDimensions = (windowDimensions: any = {}) => {
  const { innerWidth: width, innerHeight: height } = window;

  const orientationType =
    document.body.scrollHeight < document.body.scrollWidth
      ? "landscape-primary"
      : "portrait-primary";

  if (
    orientationType === "landscape-primary" &&
    windowDimensions.orientation === "portrait-primary"
  ) {
    return {
      width: windowDimensions.height,
      height: windowDimensions.width,
      orientation: orientationType,
    };
  }
  return {
    width,
    height,
    orientation: orientationType,
  };
};
interface CameraScanProps {
  onImageCapture?: (data: { img: HTMLImageElement; index: number }) => void;
  onClose?: () => void;
  status: RecognitionStatus;
  message: string;
  captureEnabled?: boolean;
}

const CameraScan = ({
  onImageCapture,
  onClose,
  status,
  message,
  captureEnabled = true,
}: CameraScanProps) => {
  const [cameraLoaded, setCameraLoaded] = useState(false);
  const classes = useStyles();
  const windowDimensionsRef = useRef<any>();
  // const imageCounterRef = useRef<number>(50);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const { t } = useTranslation();
  const webcamRef = React.useRef<Webcam>(null);

  useEffect(() => {
    windowDimensionsRef.current = windowDimensions;
  }, [windowDimensions]);

  const capture = React.useCallback(async () => {
    if (!webcamRef.current?.getScreenshot) {
      return;
    };
    // console.log("imageCounterRef.current: ", imageCounterRef.current)
    // imageCounterRef.current = imageCounterRef.current + 1;

    const { width = 0, height = 0 } =
      webcamRef.current?.getCanvas()?.getContext("2d")?.canvas || {};
    if (width === 0 || height === 0) return;
    // console.log("Image canvas: ", {width, height})
    // const isLandscape = width > height;

    const image = new Image();
    if (windowDimensionsRef.current.orientation === "landscape-primary") {
      image.src = await rotateBase64Image(webcamRef.current.getScreenshot());
    } else {
      // image.src = images[imageCounterRef.current];
      image.src = webcamRef.current.getScreenshot();
      // image.src = webcamRef.current.getScreenshot({width: 360, height: 640});
      // image.src = webcamRef.current.getScreenshot({width: 640, height: 360});
    }
    const imageee = image.src;
    // console.log("Image src: ", {imageee});
    const index = Date.now();
    const dataForNativeApp = {
      image: image.src,
      index: index,
    };
    // console.log({dataForNativeApp})
    window.postMessage(dataForNativeApp);
    image.onload = () => {
      const data: { img: HTMLImageElement; index: number } = {
        img: image,
        index,
      };
      onImageCapture(data);
    };
  }, [onImageCapture]);

  useEffect(() => {
    if (captureEnabled) {
      const timer = setInterval(() => {
        //console.log("number of tensors outside tidy: ", tf.memory().numTensors);
        capture();
      }, 100);
      return () => timer && clearInterval(timer);
    }
  }, [capture, captureEnabled]);

  useEffect(() => {
    function handleResize() {
      const { width, height, orientation } =
        getWindowDimensions(windowDimensions);

      const res = { width, height, orientation };
      // console.log("Image windowDimensions: ", {height, width, orientation})
      // const res = { width, height: orientation === 'portrait-primary' ? 700 : 460, orientation };
      setWindowDimensions(res);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const onCameraLoaded = useCallback(() => setCameraLoaded(true), []);

  const videoConstraints = useMemo(
    () => ({
      // ***** don't pass the width, it's causing zoo, effect in the camera and in the image that is being capture
      width: 1280, //{ min: windowDimensions.orientation === 'portrait-primary' ? 460 : 817},//min: windowDimensions.width},//windowDimensions.orientation === "landscape-primary" ? windowDimensions.width: windowDimensions.height,
      height: 1280, //{ min: windowDimensions.orientation === 'portrait-primary' ? 817 : 460 },//min: windowDimensions.height}, //windowDimensions.orientation === "landscape-primary" ? windowDimensions.height: windowDimensions.width,
      // height: 750,//windowDimensions.orientation === "landscape-primary" ? 480 : 750,
      facingMode: { exact: "environment" },
      frameRate: { max: 20 },
      // aspectRatio: { ideal: 1.7777777778 }
    }),
    [windowDimensions]
  );

  return (
    <>
      <div className={classes.webcamHeader}>
        <div className={classes.webcamProgress}>
          <LinearProgress
            color="primary"
            sx={{
              backgroundColor: "transparent",
              height: "1px",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "transparent",
                height: "1px",
              },
            }}
          />
        </div>
        <CloseSharpIcon onClick={onClose} />
      </div>
      <Webcam
        className={classes.webcam}
        audio={false}
        height={windowDimensions.height}
        forceScreenshotSourceSize={true}
        screenshotQuality={1}
        width={windowDimensions.width}
        ref={webcamRef}
        onLoadedData={onCameraLoaded}
        allowFullScreen
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
        onUserMediaError={onClose}
      />
      {cameraLoaded ? (
        <div className={classes.wrapper}>
          <div className={classes.wrapperContent}>
            <div
              className={classes.borderedHole}
              style={{ borderColor: statusColor[status] }}
            />
            {message && status === "ERROR" ? (
              <div className={classes.tooltipWrapper}>
                <div className={classes.messageTooltip}>{t(message)}</div>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CameraScan;
