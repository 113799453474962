import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Languages from "./languages";

i18n.use(initReactI18next).init({
  lng: "en",
  fallbackLng: "en",
  resources: Languages.reduce(
    (prev, curr) => ({ ...prev, [curr.key]: curr.source }),
    {}
  ),
  compatibilityJSON: "v3",
  defaultNS: "common",
});

export default i18n;
