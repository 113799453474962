// Base styles
import * as containerStatic from "assets/components-static-styles/components/container";
// types
type Types = any;

const container: Types = {
  ...containerStatic.default,
};

export default container;
