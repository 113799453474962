
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import configuredStore from "store/store";
import { I18nextProvider } from "react-i18next";
import i18n from "services/i18n/i18n";
import App from "App";
import { SnackbarProvider } from "notistack";
import 'react-toastify/dist/ReactToastify.css'



import { MaterialUIControllerProvider } from "context";

const container = document.getElementById("root");
const root = (ReactDOM as any).createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <SnackbarProvider
    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
    <Provider store={configuredStore}>
      <BrowserRouter>
        <MaterialUIControllerProvider>
          <I18nextProvider i18n={i18n}>
            <App />
          </I18nextProvider>
        </MaterialUIControllerProvider>
      </BrowserRouter>
    </Provider>
  </SnackbarProvider>
);
