import React from "react";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";

import brandLogo from "assets/images/logos/HeaderLogo.svg";
import { makeStyles } from "@mui/styles";
import { styles } from "./Header.styles";
import SelectLanguage from "components/SelectLanguage/SelectLaguage";

import { useTranslation } from "react-i18next";
import classNames from "classnames";
import ProgressWidget from "components/ProgressWidget/ProgressWidget";
import { useAppSelector } from "store/hooks";

interface HeaderProps {
  currentStep: number;
}

type descriptionType = "TEXT" | "CONTAINER" | "STEPS" | "LARGE_TEXT" | "SHORT";

interface StepsHeaderContent {
  [key: number]: {
    descriptionType: descriptionType;
    logo: string;
    title?: string;
    description?: string[];
    stepWidgetProgress?: number;
  };
}

const StepsHeaderContent: StepsHeaderContent = {
  0: {
    title: "header:step1:title",
    description: ["header:step1:description1", "header:step1:description2"],
    descriptionType: "TEXT",
    logo: brandLogo,
  },
  1: {
    title: "header:step2:title",
    description: ["header:step2:description"],
    descriptionType: "CONTAINER",
    logo: brandLogo,
  },
  2: {
    title: "header:step3:title",
    descriptionType: "STEPS",
    stepWidgetProgress: 1,
    logo: brandLogo,
  },
  3: {
    title: "header:step3:title",
    description: ["bla"],
    descriptionType: "STEPS",
    stepWidgetProgress: 2,
    logo: brandLogo,
  },
  4: {
    title: "header:step3:title",
    description: ["bla"],
    descriptionType: "STEPS",
    stepWidgetProgress: 3,
    logo: brandLogo,
  },
  5: {
    title: "header:step4:title",
    descriptionType: "LARGE_TEXT",
    logo: brandLogo,
  },
  6: {
    descriptionType: "SHORT",
    logo: brandLogo,
  },
};

const Header = ({ currentStep }: HeaderProps) => {
  const { t } = useTranslation();
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const isModelsLoaded = useAppSelector(state => state.imageRecognition.isModelsLoaded);

  return (
    <Card style={{ width: "100vw" }}>
      <div
        className={classNames(
          StepsHeaderContent[currentStep].descriptionType === "SHORT"
          ? classes.headerContainer_SHORT
          : classes.headerContainer,
          classes.headerContainerBG
          )}
      >
        <div className={classes.logoRow}>
          <MDBox
            component="img"
            className={classes.brandLogo}
            src={brandLogo}
            alt="Brand"
            sx={{ backgroundColor: "black" }}
          />
          <div className={classes.languageWrapper}>
            <SelectLanguage />
            <div className={classes.loadingCircle} style={{ background: isModelsLoaded ? "green" : "red" }}></div>
          </div>
        </div>
        <div
          className={classNames(
            StepsHeaderContent[currentStep].descriptionType === "LARGE_TEXT"
            ? classes.largeTitle
            : classes.title
            )}
        >
          {t(StepsHeaderContent[currentStep].title)}
        </div>
        <div className={classes.descriptionSection}>
          {StepsHeaderContent[currentStep].descriptionType === "TEXT" && (
            <div className={classes.descriptionBoxText}>
              {StepsHeaderContent[currentStep].description?.map(
                (description, index) => (
                  <div key={index} className={classes.description}>
                    {t(description)}
                  </div>
                )
              )}
            </div>
          )}
          {StepsHeaderContent[currentStep].descriptionType === "CONTAINER" && (
            <div className={classes.descriptionBoxContainer}>
              {t(StepsHeaderContent[currentStep].description)}
            </div>
          )}
          {StepsHeaderContent[currentStep].descriptionType === "STEPS" && (
            <ProgressWidget
              currentProgressStep={
                StepsHeaderContent[currentStep].stepWidgetProgress
              }
            />
          )}
        </div>
      </div>
    </Card>
  );
};

export default Header;
