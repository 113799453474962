import React, { FC, useEffect, useState, ReactElement } from "react";
import { useTranslation } from "react-i18next";

import YouTube from "react-youtube";
import MDButton from "components/MDButton";
import { ReactComponent as SandClock } from "assets/Icons/sandClock.svg";

import { makeStyles } from "@mui/styles";
import { styles } from "./Step1.styles";
import Timer from "components/Timer/Timer";

// import { setIsModelsLoaded } from "store/slices/imageRecognition";
// import { setSegmentatiomnModel } from "services/models";
// import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";
// import { useAppDispatch } from "store/hooks";
import { log } from "services/logger";
import useModels from "hooks/useModels.hook";

// const createWorker = createWorkerFactory(() => import("services/tensorflow"));
// const MODEL_URLS = [
//   process.env.REACT_APP_LOCALIZATION_MODEL_URL,
//   process.env.REACT_APP_CLASSIFICATION_MODEL_URL,
//   process.env.REACT_APP_SEGMENTATION_MODEL_URL,
// ];

interface StepProps {
  handleUpdateStep: (step: number) => void;
}

type Step1Map = {
  [key: number]: {
    Component: ReactElement;
    nextButtonText: string;
  };
};

const InnerStep1: FC<any> = ({ classes, youTubeOptions, onReady }) => {
  const { t } = useTranslation();



  return (
    <>
      <div className={classes.text}>
        <div className={classes.textBullet}>1.&nbsp;&nbsp;</div>
        <span>{t("Step1Screen:text1")}</span>
      </div>
      <div className={classes.text}>
        <div className={classes.textBullet}>2.&nbsp;&nbsp;</div>
        <span>{t("Step1Screen:text2")}</span>
      </div>
      <div className={classes.text}>
        <div className={classes.textBullet}>3.&nbsp;&nbsp;</div>
        <span>{t("Step1Screen:text3")}</span>
      </div>
      <div className={classes.youTubeContainer}>
        {youTubeOptions ? (
          <YouTube
            iframeClassName={classes.iframeClassName}
            videoId={t("Step1Screen:youtubeVideo")}
            opts={youTubeOptions}
            onReady={onReady}
          />
        ) : null}
      </div>
    </>
  );
};
const InnerStep2: FC<any> = ({ classes, setTimerDone }) => {
  // const worker = useWorker(createWorker);
  const { t } = useTranslation();
  const { isDoneLoadingModels, startLoadingModels } = useModels();
  // const dispatch = useAppDispatch();

  useEffect(() => {
    startLoadingModels();
    // setTimeout(() => {
    //   (async () => {
    //     console.log("Start Loading");
    //     // const res = await worker.loadModel(MODEL_URLS[0], MODEL_URLS[1])
    //     const segmentationModelRes = await worker.loadSegModel(MODEL_URLS[2])
    //     // console.log("Done loading", {res});
    //     // setModels(res.detectionModel, res.classificationModel)
    //     setSegmentatiomnModel(segmentationModelRes)
    //     dispatch(
    //       setIsModelsLoaded(true)
    //     );
    //   })();
    // }, 500);
  }, [])

  useEffect(() => {
    if (isDoneLoadingModels) {
      console.log("Models are loaded")
    }
  }, [isDoneLoadingModels]);

  return (
    <div className={classes.innerStep2Container}>
      <div className={classes.sandClockContainer}>
        <SandClock />
      </div>
      <div className={classes.timerText1}>{t("Step1Screen:timerText1")}</div>
      <Timer
        timerLength={5}
        timerLocalStorageKey="step1Timer"
        setTimerDone={setTimerDone}
        text="Step1Screen:timerText2"
      />
    </div>
  );
};

const Step1: FC<StepProps> = ({ handleUpdateStep }) => {
  const [youTubeOptions, setYouTubeOptions] = useState();
  const [timerDone, setTimerDone] = useState(false);
  const [step1InnerStep, setStep1InnerStep] = useState(1);
  const { t } = useTranslation();


  useEffect(() => {
    log('Hello app', {welcome: "to app"})
  }, [])
  
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const onReady = (event: any) => {
    event.target.pauseVideo();
  };

  useEffect(() => {
    const youTubePlayerWidth =
      document.getElementById("Step1Container").clientWidth;
    const options: any = {
      height: 216,
      width: youTubePlayerWidth,
    };
    setYouTubeOptions(options);
  }, []);

  const body: Step1Map = {
    1: {
      Component: (
        <InnerStep1
          classes={classes}
          youTubeOptions={youTubeOptions}
          onReady={onReady}
        />
      ),
      nextButtonText: t("Step1Screen:nextButton1"),
    },
    2: {
      Component: <InnerStep2 classes={classes} setTimerDone={setTimerDone} />,
      nextButtonText: t("Step1Screen:nextButton2"),
    },
  };

  const handleNext = () => {
    if (step1InnerStep === 1) {
      // start timer
      setStep1InnerStep(2);
    }
    if (step1InnerStep === 2) {
      handleUpdateStep(3);
    }
  };

  return (
    <div id="Step1Container" className={classes.container}>
      <div className={classes.bodyContainer}>
        {body[step1InnerStep].Component}
      </div>

      <MDButton
        style={{ width: "100%", height: "56px" }}
        color={"primary"}
        variant="contained"
        type="submit"
        onClick={handleNext}
        disabled={step1InnerStep === 2 && !timerDone}
      >
        {body[step1InnerStep].nextButtonText}
      </MDButton>
    </div>
  );
};

export default Step1;
