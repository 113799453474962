// Base styles
import * as dialogActionsStatic from "assets/components-static-styles/components/dialog/dialogActions";

// types
type Types = any;

const dialogActions: Types = {
  ...dialogActionsStatic.default,
};

export default dialogActions;
