// Helper Functions
import pxToRem from "assets/theme/functions/pxToRem";

// types
type Types = any;

const checkbox: Types = {
  styleOverrides: {
    root: {
      "& .MuiSvgIcon-root": {
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        width: pxToRem(20),
        height: pxToRem(20),
        borderRadius: pxToRem(5.6),
      },

      "&.Mui-disabled": {
        opacity: 0.5,
      },
    },
  },
};

export default checkbox;
