// Base styles
import * as stepConnectorStatic from "assets/components-static-styles/components/stepper/stepIcon";

// types
type Types = any;

const stepIcon: Types = {
  ...stepConnectorStatic.default,
};

export default stepIcon;
