// Base styles
import * as listItemStatic from "assets/components-static-styles/components/list/listItem";

// types
type Types = any;

const listItem: Types = {
  ...listItemStatic.default,
};

export default listItem;
