// Base styles
import * as listItemTextStatic from "assets/components-static-styles/components/list/listItemText";

// types
type Types = any;

const listItemText: Types = {
  ...listItemTextStatic.default,
};

export default listItemText;
