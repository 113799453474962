// Base styles
import * as iconStatic from "assets/components-static-styles/components/icon";

// types
type Types = any;

const icon: Types = {
  ...iconStatic.default,
};

export default icon;
