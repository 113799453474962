import colors from "assets/theme/base/colors";

const { text, white, transparent, light, background, primary } = colors;

interface Styles {
  [key: string]: any;
}
export const styles: Styles = {
  headerContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    height: "410px",
    padding: "20px",
  },
  headerContainer_SHORT: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    // height: "185px",
    padding: "20px",
  },
  headerContainerBG: {
    background: background.primary,
  },
  logoRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  brandLogo: {
    alignSelf: "flex-start",
    height: 36,
    width: 125,
  },
  title: {
    marginTop: "20px",
    fontSize: "18px",
    fontWeight: 600,
  },
  largeTitle: {
    marginTop: "70px",
    fontSize: "26px",
    fontWeight: 600,
  },
  descriptionSection: {
    marginTop: "20px",
    fontWeight: 400,
    fontSize: "14px",
    width: "100%",
  },
  descriptionBoxText: {
    alignSelf: "flex-start",
  },
  descriptionBoxContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50px",
    background: "#FFFFFF80",
    borderRadius: "20px",
    padding: "16px 20px",
  },
  description: {
    whiteSpace: "pre-line",
  },
  loadingCircle: {
    width: 16,
    height: 16,
    margin: "0 10px",
    borderRadius: 100,
  },
  languageWrapper: {
    display: "flex",
    alignItems: "center",
  },
};
