// Base styles
import borders from "assets/theme/base/borders";
import typography from "assets/theme/base/typography";

// Helper Functions
import pxToRem from "assets/theme/functions/pxToRem";

const { borderRadius } = borders;
const { size } = typography;

// types
type Types = any;

const menuItem: Types = {
  styleOverrides: {
    root: {
      minWidth: pxToRem(160),
      minHeight: "unset",
      padding: `${pxToRem(4.8)} ${pxToRem(16)}`,
      borderRadius: borderRadius.md,
      fontSize: size.sm,
      transition: "background-color 300ms ease, color 300ms ease",
    },
  },
};

export default menuItem;
