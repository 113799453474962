import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  wrapperContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    position: "relative",
    padding: "24px 0",
  },
  borderedHole: {
    height: "100%",
    width: 215,
    maxHeight: 450,
    borderRadius: 8,
    borderWidth: 2,
    borderStyle: "solid",
    zIndex: 10,
    boxShadow: "0 0 0 99999px #10152ccf",
    margin: "100px 0 30px",
    '@media (orientation: landscape)': {
      width: 450,
      maxHeight: 215,
      margin: 0,
    },
  },
  webcamHeader: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 2,
    padding: 12,
    fontSize: 24,
    width: "100vw",
    textAlign: "right",
    color: theme.palette.white.main,
  },
  webcam: {
    position: "fixed",
    top: 0,
    left: 0,
    objectFit: "cover",
    width: "100%",
    height: "100%"
  },
  tooltipWrapper: {
    width: "90vw",
    position: "absolute",
    bottom: 0,
    maxWidth: 400,
    zIndex: 10,
    height: 100,
    '@media (orientation: landscape)': {
      height: "auto",
      width: 345,
    },
  },
  messageTooltip: {
    background: "black",
    borderRadius: 10,
    padding: 16,
    color: "white",
    textAlign: "center",
    '@media (orientation: landscape)': {
      position: "absolute",
      bottom: 8
    },
  },
  webcamProgress: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100vw"
  }
}));
