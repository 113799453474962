// Base styles
import * as formControlLabelStatic from "assets/components-static-styles/components/form/formControlLabel";
import colors from "assets/theme-dark/base/colors";

// Helper functions
import { merge } from "lodash";

const { white } = colors;

// types
type Types = any;

const themeStyles = {
  styleOverrides: {
    label: {
      color: white.main,

      "&.Mui-disabled": {
        color: white.main,
      },
    },
  },
};

const formControlLabel: Types = merge(themeStyles, formControlLabelStatic.default);

export default formControlLabel;
