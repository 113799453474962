// Base styles
import borders from "assets/theme/base/borders";
import boxShadows from "assets/theme/base/boxShadows";

// Helper Functions
import pxToRem from "assets/theme/functions/pxToRem";

const { borderWidth } = borders;
const { md } = boxShadows;

// types
type Types = any;

const switchButton: Types = {
  defaultProps: {
    disableRipple: false,
  },

  styleOverrides: {
    switchBase: {
      "&.Mui-checked": {
        "& + .MuiSwitch-track": {
          opacity: 1,
        },
      },

      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: "0.3 !important",
      },
    },

    thumb: {
      boxShadow: md,
      borderWidth: borderWidth[1],
      borderStyle: `solid`,
    },

    track: {
      width: pxToRem(32),
      height: pxToRem(15),
      borderWidth: borderWidth[1],
      borderStyle: `solid`,
      opacity: 1,
    },

    checked: {},
  },
};

export default switchButton;
