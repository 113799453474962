import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import MDButton from "components/MDButton";
import { ReactComponent as ViImage } from "assets/images/ViImage.svg";
import { ReactComponent as QuestionMark } from "assets/images/QuestionMark.svg";

import { makeStyles } from "@mui/styles";
import { ResaultActionType, UserActions } from "types/user";
import { styles } from "./ModalsActions.styles";
import classNames from "classnames";

interface StepProps {
  handleUserAction: (action: UserActions) => void;
  resaultAction: ResaultActionType;
}

type ModalActionsMap = {
  [key in ResaultActionType]: any;
};

const ModalsActions: FC<StepProps> = ({ handleUserAction, resaultAction }) => {
  const useStyles = makeStyles(styles);
  const { t } = useTranslation();
  const classes = useStyles();

  const modalActionsMap: ModalActionsMap = {
    DONE: {
      image: <ViImage />,
      title: "ModalsActions:doneTitle",
      actionButtonText: "ModalsActions:doneButton",
      actionButton: () => handleUserAction("DONE"),
    },
    REPORT: {
      image: <QuestionMark />,
      title: "ModalsActions:questionTitle",
      text: "ModalsActions:questionText",
      actionButtonText: "ModalsActions:wrongResaultButton",
      actionButton: () => handleUserAction("WRONG_RESAULT"),
      cancelButtonText: "ModalsActions:noButton",
      cancelButton: () => console.log("noButton"),
    },
  };

  return (
    <div className={classes.actionComponentContainer}>
      <div className={classes.actionBody}>
        <div className={classes.image}>
          {modalActionsMap[resaultAction].image}
        </div>
        <div className={classes.title}>
          {t(modalActionsMap[resaultAction].title)}
        </div>
        {modalActionsMap[resaultAction].text ? (
          <div className={classes.text}>
            {t(modalActionsMap[resaultAction].text)}
          </div>
        ) : null}
      </div>
      <div
        className={classNames(
          classes.buttonsSection,
          modalActionsMap[resaultAction].cancelButton ? classes.twoButtons : ""
        )}
      >
        <MDButton
          style={{ minWidth: "150px", height: "56px" }}
          color={"primary"}
          variant="contained"
          type="submit"
          onClick={modalActionsMap[resaultAction].actionButton}
        >
          {t(modalActionsMap[resaultAction].actionButtonText)}
        </MDButton>
        {modalActionsMap[resaultAction].cancelButton ? (
          <MDButton
            style={{ minWidth: "100px", height: "56px" }}
            color={"primary"}
            variant="outlined"
            type="submit"
            onClick={modalActionsMap[resaultAction].cancelButton}
          >
            {t(modalActionsMap[resaultAction].cancelButtonText)}
          </MDButton>
        ) : null}
      </div>
    </div>
  );
};

export default ModalsActions;
