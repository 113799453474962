// Base styles
import * as sidenavStatic from "assets/components-static-styles/components/sidenav";
import colors from "assets/theme-dark/base/colors";

// Helper functions
import { merge } from "lodash";

const { background } = colors;

// types
type Types = any;

const themeStyles = {
  styleOverrides: {
    paper: {
      backgroundColor: background.sidenav,
    },
  },
};

const sidenav: Types = merge(themeStyles, sidenavStatic.default);

export default sidenav;
