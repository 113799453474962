

// Base styles
import colors from "assets/theme/base/colors";

const { transparent, dark } = colors;

// types
type Types = any;

const textField: Types = {
  styleOverrides: {
    root: {
      backgroundColor: transparent.main,
      // '& .MuiOutlinedInput-root': {  // - The Input-root, inside the TextField-root
      //   '& fieldset': {            // - The <fieldset> inside the Input-root
      //       borderColor: dark.main,   // - Set the Input border
      //   },
      // },
    },
  },
};

export default textField;
