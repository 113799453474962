import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import YouTube from "react-youtube";
import MDButton from "components/MDButton";
import { ReactComponent as IconBackground } from "assets/Icons/iconBackground.svg";
import { ReactComponent as Attention } from "assets/Icons/attention.svg";

import { makeStyles } from "@mui/styles";
import { styles } from "./InstractionsScreen.styles";

interface StepProps {
  handleUpdateStep: (step: number) => void;
}

const InstractionsScreen: FC<StepProps> = ({ handleUpdateStep }) => {
  const { t } = useTranslation();
  const [youTubeOptions, setYouTubeOptions] = useState();
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  useEffect(() => {
    const youTubePlayerWidth = document.getElementById(
      "instractionsScreenContainer"
    ).clientWidth;
    const options: any = {
      height: 216,
      width: youTubePlayerWidth,
    };
    setYouTubeOptions(options);
  }, []);

  const onReady = (event: any) => {
    event.target.pauseVideo();
  };

  return (
    <div id="instractionsScreenContainer" className={classes.container}>
      <div className={classes.bodyContainer}>
        <div className={classes.content}>
          {t("InstractionsScreen:extraInfo")}

          <div>&#9679; {t("InstractionsScreen:case1")}</div>
          <div>&#9679; {t("InstractionsScreen:case2")}</div>
        </div>
        <div className={classes.youTubeContainer}>
          {youTubeOptions ? (
            <YouTube
              iframeClassName={classes.iframeClassName}
              videoId={t("InstractionsScreen:youtubeVideo")}
              opts={youTubeOptions}
              onReady={onReady}
            />
          ) : null}
        </div>
        <div className={classes.attentionContainer}>
          <div className={classes.iconBackground}>
            <IconBackground />
            <div className={classes.attentionIcon}>
              <Attention />
            </div>
          </div>
          <div className={classes.detailsContainer}>
            <div className={classes.attentionTitle}>
              {t("InstractionsScreen:attentionTitle")}
            </div>
            <div className={classes.attentionDescription}>
              {t("InstractionsScreen:attentionDescription")}
            </div>
          </div>
        </div>
      </div>

      <MDButton
        style={{ width: "100%", height: "56px" }}
        color={"primary"}
        variant="contained"
        type="submit"
        onClick={() => handleUpdateStep(2)}
      >
        {t("InstractionsScreen:nextButton")}
      </MDButton>
    </div>
  );
};

export default InstractionsScreen;
