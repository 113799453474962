// Base styles
import * as tableCellStatic from "assets/components-static-styles/components/table/tableCell";

// types
type Types = any;

const tableCell: Types = {
  ...tableCellStatic.default,
};

export default tableCell;
