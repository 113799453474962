// Base styles
import * as iconButtonStatic from "assets/components-static-styles/components/iconButton";
// types
type Types = any;

const iconButton: Types = {
  ...iconButtonStatic.default,
};

export default iconButton;
