import colors from "assets/theme/base/colors";
import linearGradient from "assets/theme/functions/linearGradient";
import rgba from "assets/theme/functions/rgba";

const { text, white, transparent, grey, black, primary, extraColors } = colors;

interface Styles {
  [key: string]: any;
}

export const styles: Styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    circleGroup: {
        display: "flex",
        alignItems: "center"
    },
    circle: {
        width: '47px',
        height: '46px',
        borderRadius: '50%',
        background: extraColors.circleGrey,
        fontweight: 700,
        fontSize: '18px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    firstCircle: {
        marginLeft: '0px',
    },
    active: {
        background: primary.main,
        color: white.main,
        fontWeight: 700,
    },
    notActive: {
        background: white.main,
        color: rgba(black.main, 0.5),
        fontWeight: 700,
    },
    seperateLine: {
        width: '40px',
        border: 'none',
        borderTop: `1px dotted ${white.main}`,
        // color: '#fff',
        // backgroundColor: '#fff',
        height: '1px',
        margin: '0px 3px 0 1px',
    },
    activeLine: {
        borderTop: `1px dotted ${black.main}`,
    }

}

