import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GraphModel } from "@tensorflow/tfjs";
import {
  DetectedObject,
  ImageObject,
  RecognitionModules,
  RecognitionStatus,
  ResultStatus,
} from "types/imageRecognition";
import { log } from "services/logger";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";

interface InitialState {
  models: {
    filtering: GraphModel | null;
    localization: GraphModel | null;
    clasification: GraphModel | null;
  };
  msg: string;
  status: RecognitionStatus;
  result: ResultStatus | null;
  imagesStack: DetectedObject[];
  logs: any;
  isModelsLoaded: boolean;
  shouldStopImageProcess: boolean;
}
interface StatusMessage {
  msg: string;
  status: RecognitionStatus;
}

// const IMAGE_MAX_TIME_DIFFERENCE_MS = 3000;
const BASE = "imageRecognition";
// const MODEL_URLS = [
//   process.env.REACT_APP_LOCALIZATION_MODEL_URL,
//   process.env.REACT_APP_CLASSIFICATION_MODEL_URL,
// ];

const initialState: InitialState = {
  models: {
    filtering: null,
    localization: null,
    clasification: null,
  },
  msg: "",
  status: "PROGRESS",
  result: null,
  imagesStack: [],
  logs: {},
  isModelsLoaded: false,
  shouldStopImageProcess: false,
};

const imageRecognitionSlice = createSlice({
  name: BASE,
  initialState,
  reducers: {
    setIsModelsLoaded(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isModelsLoaded: action.payload,
      };
    },
    setShouldStopImageProcess(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        shouldStopImageProcess: action.payload,
      };
    },
    setMessage(state, action: PayloadAction<StatusMessage>) {
      return {
        ...state,
        msg: action.payload.msg,
        status: action.payload.status,
      };
    },
    setImages(state, action: PayloadAction<DetectedObject[]>) {
      return {
        ...state,
        imagesStack: action.payload as any,
      };
    },
    setResult(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        result: action.payload ? "POSITIVE" : "NEGATIVE",
      };
    },
    setLogs(state, action: PayloadAction<{ imageProcess: string; log: any }>) {
      // console.log("setLogs", action.payload.imageProcess);
      // const newLogsData = state.logs;
      // console.log("action.payload.imageProcess", action.payload.imageProcess)
      // console.log("action.payload.log", action.payload.log)
      // if (state.logs[action.payload.imageProcess]) {
      //   newLogsData[action.payload.imageProcess] = [action.payload.log]
      // } else {
      //   newLogsData[action.payload.imageProcess] = [action.payload.log]
      // }
      // console.log("newLogs: ", newLogsData);

      return {
        ...state,
        logs: {
          [action.payload.imageProcess]: action.payload.log,
        },
      };
    },
  },
  // extraReducers: (builder) => {
  //   builder.addCase(loadModels.fulfilled, (state, action) => {
  //     const { detectionModel, classificationModel } = action.payload;
  //     (state.models.localization as any) = detectionModel;
  //     (state.models.clasification as any) = classificationModel;
  //   });
  // },
});

export const {
  setMessage,
  setImages,
  setResult,
  setLogs,
  setIsModelsLoaded,
  setShouldStopImageProcess,
} = imageRecognitionSlice.actions;

// const createWorker = createWorkerFactory(() => import("services/tensorflow"));

// export const loadModels = createAsyncThunk(
//   `${BASE}/loadModels`,
//   (data: RecognitionModules, { rejectWithValue }) => {
//     return data;
//   }
// );

// const clasifyImage = createAsyncThunk(
//   `${BASE}/clasifyImage`,
//   async (data: DetectedObject, { getState, dispatch }) => {
//     // const worker = useWorker(createWorker);
//     // dispatch(
//     //   loadModels(await loadModel(MODEL_URLS[0], MODEL_URLS[1]))
//     // );
//     const state = getState() as RootState;
//     const { models, imagesStack } = state.imageRecognition;

//     const firstImage = imagesStack?.[0];
//     const timeDelta = data.index - (firstImage?.index || data.index);

//     // adding the DetectedObject to the stack
//     const updatedImageStack =
//       timeDelta > IMAGE_MAX_TIME_DIFFERENCE_MS
//         ? [data]
//         : [...imagesStack, data];
//     dispatch(setImages(updatedImageStack));

//     const processImage = `process_image_${data.index}`;
//     const imageLog = {
//       desctiption:
//         "image filtering and localization finished successully (stack is full)",
//       imageId: data.index,
//       timeDelta: timeDelta,
//       stackBefore: imagesStack.map((el) => el.index),
//       stackAfter: updatedImageStack.map((el) => el.index),
//     };
//     // dispatch(
//     //   setLogs({ imageProcess: `process_image_${data.index}`, log: imageLog })
//     // );
//     log(processImage, imageLog);
//     // console.log("Stack imagesStack: ", imagesStack);
//     if (updatedImageStack.length === 5) {
//       const results = await classification(
//         models.clasification,
//         updatedImageStack
//       );
//       // console.log("Stack: ", { results });
//       // const results = await Promise.all(updatedImageStack.map((el: DetectedObject) => classification(models.clasification, el)));
//       if (results) {
//         // if (results.every(el => el)) {

//         log(`process_image_${data.index}`, {
//           desctiption: "result of classification images in stack",
//           stack: updatedImageStack.map((el) => el.index),
//           clasificationResult: results,
//         });
//         dispatch(setResult(true)); // positive result
//       } else {
//         dispatch(setResult(false)); // negative result
//       }
//       setImages([]);
//       // else if (results.every(el => !el)) {
//       //   dispatch(setResult(false)); // negative result
//     }
//     // else {
//     //   setImages([]); // reset result when not all images are classified correctly
//     // }
//   }
// );

interface FilterResponse {
  [key: string]: boolean;
}

// export const processImage = createAsyncThunk(
//   `${BASE}/processImage`,
//   async (data: ImageObject, { getState, dispatch }) => {
//     // const worker = useWorker(createWorker);
//     const state = getState() as RootState;
//     const { models, imagesStack } = state.imageRecognition;
//     if (!models.localization || !models.clasification) return;
//     if (imagesStack.length >= 5) return;
//     const processImage = `process_image_${data.index}`;
//     const imageLog = {
//       imageId: data.index,
//       base64: data.data.src,
//       description: "captured image",
//     };
//     // dispatch(
//     //   setLogs({ imageProcess: `process_image_${data.index}`, log: imageLog })
//     // );
//     // console.log({imageProcess: `process_image_${data.index}`, log: imageLog})
//     log(processImage, imageLog);

//     // TODO: Remove before production:
//     // *******************
//     // if (data.shouldActiveFiltering.current) {
//     //   console.log("Filtering");
//     // filtering response:
//     // {0:false, 1:false, 2:false, 3:false, 4:false}
//     // {isGoodQuality:false, isClearQuality:false, isLowContrast:false, isBlurred:false, isOccluded (is covered ):false}
//     const {
//       isGoodQuality,
//       isClearQuality,
//       isLowContrast,
//       isBlurred,
//       isOccluded,
//     }: FilterResponse = await filtering(data);
//     log(`process_image_${data.index}`, {
//       imageId: data.index,
//       isBlurred,
//       isLowContrast,
//       description: "filtering result for image",
//     });
//     // console.log("Image: ", { isLowContrast, isBlurred });

//     if (isLowContrast) {
//       dispatch(setMessage({ msg: "step3screen:imageDark", status: "ERROR" }));
//       return;
//     }

//     if (isBlurred) {
//       dispatch(
//         setMessage({ msg: "step3screen:imageBadQuality", status: "ERROR" })
//       );
//       return;
//     }

//     // if (isGoodQuality) {
//     //   dispatch(
//     //     setMessage({ msg: "", status: "SUCCESS" })
//     //   );
//     // }

//     const localizationResult = await localization(models.localization, data);
//     log(`process_image_${data.index}`, {
//       imageId: data.index,
//       localizationResult,
//       description: "localization result for image",
//     });

//     if (localizationResult?.length < 2) {
//       dispatch(
//         setMessage({ msg: "step3screen:imageWithoutTest", status: "ERROR" })
//       );
//       return;
//     }
//     dispatch(setMessage({ msg: "", status: "SUCCESS" }));

//     dispatch(clasifyImage(localizationResult[1]));
//   }
// );

export default imageRecognitionSlice;
