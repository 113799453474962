import {
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { t } from "i18next";
import http from "services/http/http";
import { RootState } from "store/store";
import ApiException from "types/api";
import { UserState } from "types/store";
import { UserConfig, AuthData, UserLoginRequest } from "types/user";
import { toastifyError } from "utils/toastify-message";
import {
  getStoredAuthData,
  removeAuthDataFromStorage,
  storeAuthData,
} from "utils/auth.utils";

const BASE = "users";

const initialState: UserState = {
  loading: "idle",
  authData: {
    jwtToken: "",
    refreshToken: ""
  },
  userConfig: {
    username: "",
    darkMode: false,
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setAuthDataFromStorage: (state) => {
      const authData: AuthData | undefined = getStoredAuthData();
      return { ...state, authData };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateUserConfig.fulfilled, (state, action) => {
      state.userConfig = action.payload;
    });
    builder.addCase(updateUserConfig.rejected, (state, action) => {
      toastifyError(t("errors:updateFailed"));
    });
    builder.addCase(fetchUserConfig.fulfilled, (state, action) => {
      state.userConfig = action.payload;
    });
    builder.addCase(logoutUser.fulfilled, (state, action) => {
      state.authData = initialState.authData;
      removeAuthDataFromStorage();
    });
    builder.addCase(logoutUser.rejected, (state, action) => {
      state.authData = initialState.authData;
      removeAuthDataFromStorage();
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      storeAuthData(action.payload);
      state.authData = action.payload;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      const error = action.payload as any;
      toastifyError(t(`errors:${error.data.message}`));
    });
  },
});

export const loginUser = createAsyncThunk(
  `${BASE}/loginUser`,
  async (data: UserLoginRequest, { rejectWithValue }) => {
    return await http
      .request<AuthData>({
        key: "login",
        data,
      })
      .then((res) => res)
      .catch((err: ApiException) => rejectWithValue(err));
  }
);

export const logoutUser = createAsyncThunk(
  `${BASE}/logoutUser`,
  async (_, { rejectWithValue }) => {
    return await http
      .request<void>({
        key: "logout",
      })
      .then((res) => res)
      .catch((err: ApiException) => rejectWithValue(err));
  }
);

export const fetchUserConfig = createAsyncThunk(
  `${BASE}/fetchUserConfig`,
  async (_, { rejectWithValue }) => {
    return await http
      .request<UserConfig>({
        key: "getUserConfig",
      })
      .then((res) => res)
      .catch((err: ApiException) => rejectWithValue(err));
  }
);

export const updateUserConfig = createAsyncThunk(
  `${BASE}/updateUserConfig`,
  async (data: Partial<UserConfig>, { rejectWithValue }) => {
    return await http
      .request<UserConfig>({
        key: "updateUserConfig",
        data,
      })
      .then((res) => res)
      .catch((err: ApiException) => rejectWithValue(err));
  }
);

export const getAuthData = (state: RootState) => state.user.authData;
export const getUserConfig = (state: RootState) => state.user.userConfig;

export default userSlice;
