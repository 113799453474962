// Base styles
import * as flatpickrStatic from "assets/components-static-styles/components/flatpickr";
import colors from "assets/theme-dark/base/colors";

// Helper functions
import { merge } from "lodash";
import rgba from "assets/theme-dark/functions/rgba";

const { info, white, gradients } = colors;

// types
type Types = any;

const themeStyles = {
  ".flatpickr-day:hover, .flatpickr-day:focus, .flatpickr-day.nextMonthDay:hover, .flatpickr-day.nextMonthDay:focus":
    {
      background: rgba(info.main, 0.28),
    },

  ".flatpickr-day.today": {
    background: info.main,
    color: white.main,

    "&:hover, &:focus": {
      background: `${info.focus} !important`,
    },
  },

  ".flatpickr-day.selected, .flatpickr-day.selected:hover, .flatpickr-day.nextMonthDay.selected, .flatpickr-day.nextMonthDay.selected:hover, .flatpickr-day.nextMonthDay.selected:focus":
    {
      background: `${gradients.info.state} !important`,
      color: white.main,
    },

  ".flatpickr-months .flatpickr-next-month:hover svg, .flatpickr-months .flatpickr-prev-month:hover svg":
    {
      color: `${info.main} !important`,
      fill: `${info.main} !important`,
    },
};

const flatpickr: Types = merge(themeStyles, flatpickrStatic.default);

export default flatpickr;
