interface Styles {
  [key: string]: any;
}

export const styles: Styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  bodyContainer: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100% - 56px)",
    alignItems: "flex-start",
  },
  text: {
    display: "flex",
    fontSize: "14px",
    lineHeight: "18px",
    margin: "6px 0 5px 0"
  },
  textBullet: {
    fontSize: "18px",
    fontWeight: 700,
    // lineHeight: "18px"
  },
  youTubeContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    marginTop: "30px"
  },
  iframeClassName: {
    borderRadius: "20px",
  },
  innerStep2Container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },
  sandClockContainer: {
    margin: "63px 0 16px 0"
  },
  timerText1: {
    marginBottom: "50px"
  },
  toastContainer: {
    position: "fixed",
    width: "100%",
    top: 80,
    left: 8,
    bottom: 8,
  },
};

export const toastStyles: Styles = {
  fontFamily: "Nunito Sans, sans-serif",
  position: "fixed",
  top: 80,
  left: 0,
  height: 100,
  width: "calc(100% - 20px)",
  textAlign: "center",
  background: "black",
  color: "white",
  borderRadius: 10,
  fontSize: 16,
  fontWeight: 600,
  margin: "0 10px",
}
