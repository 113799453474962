// Base styles
import * as svgIconStatic from "assets/components-static-styles/components/svgIcon";

// types
type Types = any;

const svgIcon: Types = {
  ...svgIconStatic.default,
};

export default svgIcon;
