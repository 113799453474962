// Base styles
import * as linearProgressStatic from "assets/components-static-styles/components/linearProgress";
import colors from "assets/theme/base/colors";

// Helper functions
import { merge } from "lodash";

const { light } = colors;

// types
type Types = any;

const themeStyles = {
  styleOverrides: {
    colorPrimary: {
      backgroundColor: 'transparent',//light.main,
    },

    colorSecondary: {
      backgroundColor: 'transparent',//light.main,
    },
  },
};

const linearProgress: Types = merge(themeStyles, linearProgressStatic.default);

export default linearProgress;
