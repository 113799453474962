import React from "react";

import MainPage from "layouts/Main/MainPage/MainPage";
import { Route } from "interfaces/infra";

const routes: Route[] = [
  {
    type: "collapse",
    noCollapse: true,
    name: "mainpage",
    key: "mainpage",
    route: "/mainpage",
    // icon: <HomepageIcon />,
    component: <MainPage />,
    // role: [UserRole.USER, UserRole.ADMIN],
    minWindowSize: 1200,
  },
 ];

export default routes;
