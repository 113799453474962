// Base styles
import * as cardContentStatic from "assets/components-static-styles/components/card/cardContent";

// types
type Types = any;

const cardContent: Types = {
  ...cardContentStatic.default,
};

export default cardContent;
