// Base styles
import * as indexStatic from "assets/components-static-styles/components/tabs/index";

// types
type Types = any;

const tabs: Types = {
  ...indexStatic.default,
};

export default tabs;
