export const globalColors = {
    primary1: {
        main: '#1C6BA4'
    },
    primary2: {
        main: '#DCEDF9'
    },
    primary3: {
        main: '#FFFFFF'
    },
    primary4: {
        main: '#DCEDF9'
    },
    secondary1: {
        main: '#10152C'
    },
    secondary2: {
        main: '#29282D'
    },
    secondary3: {
        main: '#D9D9D9'
    },
    secondary4: {
        main: '#E4E8EE'
    },
    secondary5: {
        main: '#F5E1E9'
    },
    secondary6: {
        main: '#F44334'
    },
    secondary7: {
        main: '#4CAF50',
    },
    darkPass: {
        main: '#505054'
    },
    success: {
        main: "#40FF53",
    },
    warning: {
        main: "#FBFF40",
    },
    error: {
        main: "#FD442B",
    },
}