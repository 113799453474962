import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import colors from "assets/theme/base/colors";

export const useStyles = makeStyles((theme: Theme) => ({
  modalWrapper: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 80,
    bottom: 0,
    zIndex: 2,
    background: colors.background.primary,
    backdropFilter: "contrast(0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  modalContent: {
    maxWidth: 320,
    width: "90vw",
    background: theme.palette.white.main,
    padding: "40px 32px 32px",
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  modalTitle: {
    fontSize: 16,
    fontWeight: 700,
    margin: "16px 0 8px",
    textAlign: "center",
  },
  modalDescription: {
    fontSize: 16,
    fontWeight: 400,
    textAlign: "center",
  },
  loader: {
    position: "relative",
  },
  actions: {
    marginTop: 16,
  },
  btn: {
    height: 56,
    width: 150,
  }
}));

