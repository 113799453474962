// Base styles
import * as dividerStatic from "assets/components-static-styles/components/divider";
import colors from "assets/theme-dark/base/colors";

// Helper functions
import { merge } from "lodash";

const { dark } = colors;

// types
type Types = any;

const themeStyles = {
  styleOverrides: {
    root: {
      backgroundColor: dark.main,
      borderColor: `#ff000007`,
    },
  },
};

const divider: Types = merge(themeStyles, dividerStatic.default);

export default divider;
