

import {
  useState,
  useEffect,
  JSXElementConstructor,
  Key,
  ReactElement,
} from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";

//  Dark Mode themes
import themeDark from "assets/theme-dark";


import routes from "routes";

//  contexts
import { useMaterialUIController, setMiniSidenav } from "context";

import { RootState } from "store/store";
import { ConfirmDialogProvider } from "components/ConfirmDialog";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, direction, layout, sidenavColor, darkMode } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [isUserAssosiated, setIsUserAssosiated] = useState(false);
  const { pathname } = useLocation();

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes: any[], isUserAssosiated: boolean): any => {
    return allRoutes.map(
      (route: {
        collapse: any;
        route: string;
        component: ReactElement<any, string | JSXElementConstructor<any>>;
        key: Key;
        shouldBeAssosiated?: boolean;
      }) => {
        if (
          (route.route && !route.shouldBeAssosiated) ||
          (route.route && route.shouldBeAssosiated && isUserAssosiated)
        ) {
          return (
            <Route
              path={route.route}
              element={route.component}
              key={route.key}
            />
          );
        }

        return null;
      }
    );
  };

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <ConfirmDialogProvider>
        <Routes>
          {getRoutes(routes, isUserAssosiated)}
          <Route path="*" element={<Navigate to="/mainpage" />} />
        </Routes>
      </ConfirmDialogProvider>
    </ThemeProvider>
  );
}
