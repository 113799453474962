import React, {
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import CameraScan from "components/CameraScan/CameraScan";
import MDButton from "components/MDButton";
import { ReactComponent as CellPhoneTest } from "assets/images/step3.svg";
import { useTranslation } from "react-i18next";
import { LoaderModelModal } from "components/LoaderModelModal/LoaderModelModal";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useStyles } from "./Step3.styles";
import { CameraDeniedModal } from "components/CameraDeniedModal/CameraDeniedModal";
import { checkCameraPermission } from "utils/camera.util";
import { processImage } from "services/models";
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";

const createWorker = createWorkerFactory(() => import("services/models"));

interface StepProps {
  handleUpdateStep: (step: number) => void;
}

const Step3: FC<StepProps> = ({ handleUpdateStep }) => {
  const [showCamera, setShowCamera] = React.useState(false);
  const shouldActiveFiltering = useRef(true);
  const [isFiltering, setIsFiltering] = useState(true);
  const [permisionDeniedVissible, setPermisionDeniedVissible] = useState(false);
  const [screenOrientation, setScreenOrientation] =
    useState("portrait-primary");
  const msg = useAppSelector((state) => state.imageRecognition.msg);
  const status = useAppSelector((state) => state.imageRecognition.status);
  const result = useAppSelector((state) => state.imageRecognition.result);
  const imageStackLength = useAppSelector(
    (state) => state.imageRecognition.imagesStack.length
  );
  const imageStack = useAppSelector(
    (state) => state.imageRecognition.imagesStack
  );
  const worker = useWorker(createWorker);

  const isModelsLoaded = useAppSelector(
    (state) => state.imageRecognition.isModelsLoaded
  );

  const shouldStopImageProcess = useAppSelector(
    (state) => state.imageRecognition.shouldStopImageProcess
  );

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const openCamera = useCallback(async () => {
    try {
      await checkCameraPermission();
      setPermisionDeniedVissible(false);
      setShowCamera(true);
    } catch {
      setPermisionDeniedVissible(true);
    }
  }, []);

  const closeCamera = useCallback(() => {
    setShowCamera(false);
  }, []);

  const setStyle = () => {
    const orientationType =
      document.body.scrollHeight < document.body.scrollWidth
        ? "landscape-primary"
        : "portrait-primary";

    setScreenOrientation(orientationType);
  };

  const captureImage = useCallback((data: any) => {
    const processImageData = {
      data: data.img,
      index: data.index,
      shouldActiveFiltering: shouldActiveFiltering,
    };
    try {
      if (!shouldStopImageProcess) {
        processImage(processImageData, shouldStopImageProcess);
      } else {
        console.log("Image process stopped")
      }
    } catch (error) {
      console.error("processImage ERROR: ", error);
    }
    // worker.processImage(processImageData, imageStack);
  }, [shouldStopImageProcess]);

  useEffect(() => {
    setStyle();
    function handleResize() {
      setStyle();
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (result) {
      handleUpdateStep(5);
    }
  }, [result]);

  const handleToggleFiltering = () => {
    shouldActiveFiltering.current = !shouldActiveFiltering.current;
    setIsFiltering((prev) => !prev);
  };

  if (permisionDeniedVissible) {
    return (
      <CameraDeniedModal tryAgain={() => setPermisionDeniedVissible(false)} />
    );
  }

  return (
    <div className={classes.container}>
      <LoaderModelModal
        modelLoaded={isModelsLoaded}
        title={t("step3screen:modalTitle")}
        description={t("step3screen:pleaseImproveConnection")}
      />
      <div
        hidden={screenOrientation === "landscape-primary"}
        className={classes.bodyContainer}
      >
        <div className={classes.bodyTitle}>{t("step3screen:bodyTitle")}</div>
        <div className={classes.bodyText}>{t("step3screen:bodyText")}</div>
        <div className={classes.bodyImage}>
          <CellPhoneTest />
        </div>
      </div>
      <MDButton
        style={{ width: "100%", height: "56px" }}
        color={"primary"}
        variant="contained"
        type="submit"
        onClick={openCamera}
        disabled={!isModelsLoaded}
      >
        {t("step3screen:captureButton")}
      </MDButton>
      {showCamera ? (
        <CameraScan
          status={status}
          message={msg}
          captureEnabled={imageStackLength < 5}
          onImageCapture={captureImage}
          onClose={closeCamera}
        />
      ) : null}
    </div>
  );
};

export default Step3;
