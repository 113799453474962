// Base styles
import * as autocompleteStatic from "assets/components-static-styles/components/form/autocomplete";
import { merge } from "lodash";
import colors from "assets/theme/base/colors";

const { text, white, transparent, light, dark, gradients } = colors;

// types
type Types = any;

const themeStyles = {
  styleOverrides: {
    // root: {
    //   '& .MuiOutlinedInput-root': {  // - The Input-root, inside the TextField-root
    //     '& fieldset': {            // - The <fieldset> inside the Input-root
    //         borderColor: dark.main,   // - Set the Input border
    //     },
    // },
    // },
    popper: {
      color: text.main,
      backgroundColor: `${white.main} !important`,
    },

    paper: {
      backgroundColor: transparent.main,
    },

    option: {
      color: text.main,

      "&:hover, &:focus, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus": {
        backgroundColor: light.main,
        color: dark.main,
      },

      '&[aria-selected="true"]': {
        backgroundColor: `${light.main} !important`,
        color: `${dark.main} !important`,
      },
    },

    noOptions: {
      color: text.main,
    },

    groupLabel: {
      color: dark.main,
    },

    loading: {
      color: text.main,
    },

    tag: {
      backgroundColor: gradients.dark.state,
      color: white.main,

      "& .MuiSvgIcon-root, & .MuiSvgIcon-root:hover, & .MuiSvgIcon-root:focus": {
        color: white.main,
      },
    },
  },
};

const autocomplete: Types = merge(themeStyles, autocompleteStatic.default);

export default autocomplete;
