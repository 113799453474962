// Base styles
import * as appBarStatic from "assets/components-static-styles/components/appBar";

// types
type Types = any;

const appBar: Types = {
  ...appBarStatic.default,
};

export default appBar;
