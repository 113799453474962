// Base styles
import * as listIndexStatic from "assets/components-static-styles/components/list/index";

// types
type Types = any;

const list: Types = {
  ...listIndexStatic.default,
};

export default list;
