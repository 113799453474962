import colors from "assets/theme/base/colors";

const { text, white, transparent, light, background, primary } = colors;

interface Styles {
  [key: string]: any;
}
export const styles: Styles = {
    timerContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: "118px",
        padding: "12px 20px 0 20px",
        backgroundColor: background.primary,
        borderRadius: "28px"
      },
      timerText2: {
        fontSize: "14px",
        textAlign: "center",
        lineHight: "18px"
      },
      timer: {
        fontSize: "50px",
        lineHeight: "147%"
      }
}