// Base styles
import * as formLabelStatic from "assets/components-static-styles/components/form/formLabel";
import colors from "assets/theme/base/colors";

// Helper functions
import { merge } from "lodash";

const { text } = colors;

// types
type Types = any;

const themeStyles = {
  styleOverrides: {
    root: {
      color: text.main,
    },
  },
};

const formLabel: Types = merge(themeStyles, formLabelStatic.default);

export default formLabel;
