// Base styles
import * as dividerStatic from "assets/components-static-styles/components/divider";
import colors from "assets/theme/base/colors";

// Helper functions
import { merge } from "lodash";

const { extraColors } = colors;

// types
type Types = any;

const themeStyles = {
  styleOverrides: {
    root: {
      backgroundColor: `${extraColors.lightGreyBorder}90`,
      borderColor: `#ff000007`,
    },
  },
};

const divider: Types = merge(themeStyles, dividerStatic.default);

export default divider;
