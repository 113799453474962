// Base styles
import * as cardIndexStatic from "assets/components-static-styles/components/card/index";
// Base styles
import colors from "assets/theme-dark/base/colors";
import borders from "assets/theme-dark/base/borders";

//  Helper Function
import rgba from "assets/theme-dark/functions/rgba";

const { black, text, background } = colors;
const { borderWidth } = borders;
// Helper Functions
import { merge } from "lodash";

// types
type Types = any;

const themeStyles: Types = {
  styleOverrides: {
    root: {
      color: text.primary.main,
      backgroundColor: background.card,
      border: `${borderWidth[0]} solid ${rgba(black.main, 0.125)}`,
    },
  },
};

const card = merge(themeStyles, cardIndexStatic.default);

export default card;
