import colors from "assets/theme/base/colors";

const { text, white, transparent, light, background, primary } = colors;

interface Styles {
  [key: string]: any;
}
export const styles: Styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  bodyContainer: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100% - 56px)",
    alignItems: "flex-start",
  },
  text: {
    display: "flex",
    fontSize: "14px",
    lineHeight: "18px",
    margin: "6px 0 5px 0",
  },
  textBullet: {
    fontSize: "18px",
    fontWeight: 700,
  },
  youTubeContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    marginTop: 20,
  },
  iframeClassName: {
    borderRadius: "20px",
  },
  innerStep2Container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  sandClockContainer: {
    margin: "63px 0 16px 0",
  },
  timerText1: {
    marginBottom: "50px",
  },
};
