import React from "react";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { ReactComponent as Vi } from "assets/Icons/Vi.svg";

import { styles } from "./ProgressWidget.styles";

interface ProgressWidgetProps {
  currentProgressStep: number;
}

const ProgressWidget = ({ currentProgressStep }: ProgressWidgetProps) => {
  const classes = makeStyles(styles)();
  const progressCircles = [1, 2, 3];
  return (
    <div className={classes.container}>
      {progressCircles.map((circle, index) => {
        return (
          <div key={index} className={classes.circleGroup}>
            <div
              key={index}
              className={classNames(
                classes.circle,
                currentProgressStep >= circle
                  ? classes.active
                  : classes.notActive,
                circle === 3 ? classes.firstCircle : ""
              )}
            >
              {(currentProgressStep > circle) ? <Vi /> : circle}
            </div>
            {circle < 3 ? (
              <div
                className={classNames(
                  classes.seperateLine,
                  currentProgressStep > circle ? classes.activeLine : ""
                )}
              />
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default ProgressWidget;
