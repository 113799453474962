import React, { useState, FC } from "react";
import { useTranslation } from "react-i18next";

import MDButton from "components/MDButton";
import { Checkbox } from "@mui/material";
import { ReactComponent as File } from "assets/Icons/file.svg";

import classNames from "classnames";
import { useMaterialUIController } from "context";
import { makeStyles } from "@mui/styles";
import { CameraDeniedModal } from "components/CameraDeniedModal/CameraDeniedModal";
import { checkCameraPermission } from "utils/camera.util";
import { styles } from "./TermsAndConditions.styles";
interface StepProps {
  handleUpdateStep: (step: number) => void;
}

const TermsAndConditions: FC<StepProps> = ({ handleUpdateStep }) => {
  const [permisionDeniedVissible, setPermisionDeniedVissible] = useState(false);
  const [{ direction }] = useMaterialUIController();
  const [enableNext, setEnableNext] = useState(false);
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { t } = useTranslation();

  const onCheckTerms = (value: any) => {
    setEnableNext(value?.target?.checked);
  };

  const goNextStep = async () => {
    try {
      await checkCameraPermission();
      setPermisionDeniedVissible(false);
      handleUpdateStep(1);
    } catch {
      setPermisionDeniedVissible(true);
    }
  };

  if (permisionDeniedVissible) {
    return (<CameraDeniedModal tryAgain={() => handleUpdateStep(1)}/>)
  }

  return (
    <div className={classes.container}>
      <div className={classes.bodyContainer}>
        <div
          className={classNames(
            classes.bodyContent,
            direction === "ltr"
              ? classes.bodyContentLTR
              : classes.bodyContentRTL
          )}
        >
          <div className={classes.fileRow}>
            <File />
            <div
              className={classNames(
                classes.detailsContainer,
                direction === "ltr"
                  ? classes.detailsContainerLTR
                  : classes.detailsContainerRTL
              )}
            >
              <div className={classes.detailsTitle}>
                {t("TermsAndConditions:title")}
              </div>
              <div className={classes.date}>28/06/2023</div>
            </div>
          </div>
          <div className={classes.content}>
            {t("TermsAndConditions:description")}
          </div>
        </div>
        <div className={classes.approveCheckBoxContainer}>
          <Checkbox
            classes={{
              root:
                direction === "ltr" ? classes.checkBoxLTR : classes.checkBoxRTL,
            }}
            onChange={onCheckTerms}
          />
          {t("TermsAndConditions:checkBox:label")}
        </div>
      </div>
      <MDButton
        style={{ width: "100%", height: "56px" }}
        color={"primary"}
        variant="contained"
        type="submit"
        onClick={goNextStep}
        disabled={!enableNext}
      >
        {t("common:nextScreen")}
      </MDButton>
    </div>
  );
};

export default TermsAndConditions;
