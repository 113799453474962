import colors from "assets/theme/base/colors";

const { text, white, transparent, light, background, primary } = colors;

interface Styles {
  [key: string]: any;
}
export const styles: Styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  bodyContainer: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100% - 56px)",
    alignItems: "flex-start",
  },
  bodyContent: {
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
  },
  bodyContentLTR: {
    paddingLeft: "12px",
  },
  bodyContentRTL: {
    paddingRight: "12px",
  },
  content: {
    fontSize: "14px",
    fontWeight: 400,
    whiteSpace: "pre-line",
  },
  fileRow: {
    display: "flex",
    marginBottom: "14px",
  },
  detailsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  detailsContainerLTR: {
    marginLeft: "25px",
  },
  detailsContainerRTL: {
    marginRight: "25px",
  },
  detailsTitle: {
    display: "flex",
    flexDirection: "column",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "20px",
  },
  date: {
    fontSize: "14px",
    fontWeight: 400,
  },
  approveCheckBoxContainer: {
    margin: "25px 0 15px",
    fontSize: "14px",
    fontWeight: 400,
  },
  checkBoxLTR: {
    padding: "9px 9px 9px 0px!important",
  },
  checkBoxRTL: {
    padding: "9px 0px 9px 9px!important",
  },
};
