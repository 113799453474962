import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import MDButton from "components/MDButton";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmDialogTypes, DialogOptions } from ".";

const ConfirmDialog: FC<
  {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
  } & DialogOptions
> = ({ isOpen, onClose, onConfirm, ...options }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={isOpen} onClose={onClose} >
      {isOpen ? <>
        <DialogTitle>{options.header || ""}</DialogTitle>
        <DialogContent>
          <DialogContentText>{options.body || ""}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {[ConfirmDialogTypes.ALERT, ConfirmDialogTypes.WARNING].includes(
            options.type
          ) && (
              <MDButton
                variant="outlined"
                onClick={onClose}
                color="secondary"
                autoFocus={options.type === ConfirmDialogTypes.ALERT}
              >
                {options.cancelLabel ||
                  t(
                    `confirmDialog:${options.type === ConfirmDialogTypes.ALERT ? "No" : "Cancel"
                    }`
                  )}
              </MDButton>
            )}
          <MDButton
            color="primary"
            onClick={onConfirm}
            autoFocus={options.type !== ConfirmDialogTypes.ALERT}
          >
            {options.okLabel ||
              t(
                `confirmDialog:${options.type === ConfirmDialogTypes.ALERT ? "Yes" : "Ok"
                }`
              )}
          </MDButton>
        </DialogActions>
      </> :
        null}
    </Dialog>
  );
};

export default ConfirmDialog;
