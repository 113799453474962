export const mediaConstrains = {
  video: true,
  audio: false,
  facingMode: { exact: "environment" }
};

export const checkCameraPermission = (): Promise<void> => {
  if (!navigator.mediaDevices.getUserMedia) return Promise.reject();
  return navigator.mediaDevices
    .getUserMedia(mediaConstrains)
    .then(stream => {
      stream.getVideoTracks().forEach((track) => track.stop());
    });
}
