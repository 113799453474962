import * as tf from "@tensorflow/tfjs";
import { GraphModel } from "@tensorflow/tfjs";
import {
  DetectedObject,
  ImageObject,
  RecognitionModules,
  RecognitionStatus,
  ResultStatus,
} from "types/imageRecognition";
import { classification, deleteModels, filtering, localization, printTS_memory } from "./tensorflow";
import configuredStore from "store/store";
import { log } from "services/logger";
import {
  setImages,
  setMessage,
  setResult,
  setShouldStopImageProcess,
} from "store/slices/imageRecognition";

interface FilterResponse {
  [key: string]: boolean;
}

const IMAGE_MAX_TIME_DIFFERENCE_MS = 5000;
const IMAGE_STACK_SIZE = 5;

let imagesStack: DetectedObject[] = [];

export let modelLocalization: tf.GraphModel;
export let modelClasification: tf.GraphModel;

export const setModels = (localization: any, classification: any) => {
  console.log("setModels: ", localization, classification)
  modelLocalization = localization;
  modelClasification = classification;
};

export const processImage = async (
  data: ImageObject,
  shouldStopProcess: boolean
) => {
  console.log("processImage image index: ", data.index)

  if (!modelLocalization || !modelClasification) return;
  if (imagesStack.length >= IMAGE_STACK_SIZE) return;
  //   if (imagesStack.length === 3) {
  //     configuredStore.dispatch(setShouldStopImageProcess(true))
  //   }
  const processImage = `process_image_${data.index}`;
  // console.log("data: ", data)
  const imageLog = {
    imageId: data.index,
    description: "captured image",
  };
  // console.log({imageProcess: `process_image_${data.index}`, log: imageLog})
  log(processImage, imageLog);

  // filtering response:
  // {0:false, 1:false, 2:false, 3:false, 4:false}
  // {isGoodQuality:false, isClearQuality:false, isLowContrast:false, isBlurred:false, isOccluded (is covered ):false}
  try {
    const {
      isGoodQuality,
      isClearQuality,
      isLowContrast,
      isBlurred,
      isOccluded,
    }: FilterResponse = await filtering(data);
    log(`process_image_${data.index}`, {
      imageId: data.index,
      isBlurred,
      isLowContrast,
      description: "filtering result for image",
    });
    // console.log("Image: ", { isLowContrast, isBlurred });

    if (isLowContrast) {
      configuredStore.dispatch(
        setMessage({ msg: "step3screen:imageDark", status: "ERROR" })
      );
      return;
    }

    if (isBlurred) {
      configuredStore.dispatch(
        setMessage({ msg: "step3screen:imageBadQuality", status: "ERROR" })
      );
      return;
    }
  } catch (error) {
    console.error("Filtering error: ", error);
  }

  // if (isGoodQuality) {
  //   dispatch(
  //     setMessage({ msg: "", status: "SUCCESS" })
  //   );
  // }
  try {
    const localizationResult = await localization(modelLocalization, data);

    if (localizationResult?.length < 2) {
      configuredStore.dispatch(
        setMessage({ msg: "step3screen:imageWithoutTest", status: "ERROR" })
      );

      return;
    }

    log(`process_image_${data.index}`, {
      imageId: data.index,
      localizationResult: {
        bbox:localizationResult[0].bbox,
        class:localizationResult[0].class,
        conf:localizationResult[0].conf,
        aspectRatio:localizationResult[0].aspectRatio
      },
      description: "localization result for image",
    });

    configuredStore.dispatch(setMessage({ msg: "", status: "SUCCESS" }));

    clasifyImage(localizationResult[1], shouldStopProcess);
  } catch (error) {
    console.error("Localization error: ", error)
  }
};

export const printModels = () => {
  console.log({modelLocalization, modelClasification})
}

export const _deleteModels = () => {
  console.log({_deleteModels})
  setTimeout(() => {
    printTS_memory("1");
    deleteModels({
      detectionModel: modelLocalization,
      classificationModel: modelClasification
    })
    modelClasification = undefined;
    modelLocalization = undefined;
    printTS_memory("2");
  }, 500);
}
const clasifyImage = async (
  data: DetectedObject,
  shouldStopProcess: boolean
) => {
  // adding the DetectedObject to the stack
  const updatedImageStack = getUpdatedStack(imagesStack, data);
  imagesStack = updatedImageStack;

  const processImage = `process_image_${data.index}`;
  const imageLog = {
    desctiption:
      `Inside classification, stack length: ,${updatedImageStack.length}`,
  };
  log(processImage, imageLog);
  //   console.log("updatedImageStack: ", updatedImageStack.length);
  //   console.log({ imagesStack, updatedImageStack });
  try {
    if (imagesStack.length === IMAGE_STACK_SIZE && !shouldStopProcess) {
      const processImage = `process_image_${data.index}`;
      const imageLog = {
        desctiption:
          "image filtering and localization finished successully (stack is full)",
      };
      log(processImage, imageLog);

      configuredStore.dispatch(setShouldStopImageProcess(true));
      // {resault: trueCount > falseCount, trueCount, }
      const {resault, trueCount, falseCount} = await classification(modelClasification, imagesStack);
      if (resault) {
        log(`process_image_${data.index}`, {
          desctiption: "resault of classification images in stack",
          clasificationResult: resault,
          trueCount,
          falseCount
        });
        configuredStore.dispatch(setResult(true)); // positive result
      } else {
        configuredStore.dispatch(setResult(false)); // negative result
      }
      imagesStack = [];
      _deleteModels()
    }
  } catch (error) {
    console.error("Classification error: ", error)
    clearHalfImageStack();
    configuredStore.dispatch(setShouldStopImageProcess(false));
    _deleteModels()
  } 
};

const getUpdatedStack = (
  imagesStackArr: DetectedObject[],
  imageData: DetectedObject
) => {
  const shouldRemoveFirstItem = checkMinimumDuration(imagesStackArr, imageData);

  let updatedArr: DetectedObject[] = imagesStackArr;
  //   console.log({ shouldRemoveFirstItem });
  if (shouldRemoveFirstItem) {
    // console.log("1 imagesStackArr", imagesStackArr);
    imagesStackArr[0].crop.dispose();
    updatedArr = imagesStackArr.slice(1);
    // console.log("2 updatedArr", updatedArr);
  }
  const res = [...updatedArr, imageData];

  //   console.log("1 updatedArr.length: ", updatedArr.length)
  return res;
};

const checkMinimumDuration = (
  imagesStackArr: DetectedObject[],
  value?: DetectedObject
): boolean => {
  const itemToCheck = value || imagesStackArr[imagesStackArr.length - 1];
  const timeDelta =
    itemToCheck.index - (imagesStackArr[0]?.index || itemToCheck.index);
  //   console.log({ timeDelta });
  const res = timeDelta > IMAGE_MAX_TIME_DIFFERENCE_MS;
  return res;
};

const clearHalfImageStack = () => {
  const stackHalf = Math.floor(IMAGE_STACK_SIZE / 2);
  imagesStack.slice(0, stackHalf + 1);
};
