// types
type Types = any;

const tabs: Types = {
  styleOverrides: {
    root: {
      height: "auto",
      minHeight: "auto",
    },

    flexContainer: {},

    fixed: {
      overflow: "unset !important",
      overflowX: "unset !important",
      height: "auto",
    },

    vertical: {},

    indicator: {},
  },
};

export default tabs;
