import react from 'react';
import { FC, useEffect, useState } from 'react';
import { createWorkerFactory, useWorker } from "@shopify/react-web-worker";

import { setIsModelsLoaded } from "store/slices/imageRecognition";
// import { setSegmentatiomnModel } from "services/models";

import { useAppDispatch } from "store/hooks";
import { setModels } from 'services/models';

const createWorker = createWorkerFactory(() => import("services/tensorflow"));

const MODEL_URLS = [
    process.env.REACT_APP_LOCALIZATION_MODEL_URL,
    process.env.REACT_APP_CLASSIFICATION_MODEL_URL,
    // process.env.REACT_APP_SEGMENTATION_MODEL_URL,
];

const useModels = () => {
    const [isDoneLoadingModels, setIsDoneLoadingModels] = useState(false);

    const dispatch = useAppDispatch();
    const worker = useWorker(createWorker);

    const startLoadingModels = () => {
        setTimeout(() => {
            (async () => {
              console.log("Start Loading");
              const res = await worker.loadModel(MODEL_URLS[0], MODEL_URLS[1])
            //   const segmentationModelRes = await worker.loadSegModel(MODEL_URLS[2])
              console.log("Done loading", {res});
              setModels(res.detectionModel, res.classificationModel)
            //   setSegmentatiomnModel(segmentationModelRes)
              dispatch(
                setIsModelsLoaded(true)
              );
              setIsDoneLoadingModels(true);
            })();
        }, 500);
    }
    const res = {
        startLoadingModels,
        isDoneLoadingModels: isDoneLoadingModels,
    }
    return res;
}

export default useModels;