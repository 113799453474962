// types
type Types = any;

const formLabel: Types = {
  styleOverrides: {
    root: {},
  },
};

export default formLabel;
