import { configureStore } from "@reduxjs/toolkit";
import imageRecognition from "./slices/imageRecognition";
import userSlice from "./slices/user";

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    imageRecognition: imageRecognition.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      // Ignore these action types
      ignoredActions: ['imageRecognition/loadModels/fulfilled'],
      // Ignore these paths in the state
      ignoredPaths: ['imageRecognition.models', 'imageRecognition.imagesStack'],
    },
  }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
