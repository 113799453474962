// Base styles
import * as dialogTitleStatic from "assets/components-static-styles/components/dialog/dialogTitle";

// types
type Types = any;

const dialogTitle: Types = {
  ...dialogTitleStatic.default,
};

export default dialogTitle;
