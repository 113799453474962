// Base styles
import * as sidenavStatic from "assets/components-static-styles/components/sidenav";
import colors from "assets/theme/base/colors";

// Helper functions
import { merge } from "lodash";

const { white } = colors;

// types
type Types = any;

const themeStyles = {
  styleOverrides: {
    paper: {
      backgroundColor: white.main,
    },
  },
};

const sidenav: Types = merge(themeStyles, sidenavStatic.default);

export default sidenav;
