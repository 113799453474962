// Base styles
import colors from "assets/theme-dark/base/colors";
import * as stepperIndexStatic from "assets/components-static-styles/components/stepper/index";
import boxShadows from "assets/theme-dark/base/boxShadows";

// Helper functions
import { merge } from "lodash";
import linearGradient from "assets/theme/functions/linearGradient";

const { transparent, gradients } = colors;
const { colored } = boxShadows;

// types
type Types = any;

const themeStyles = {
  styleOverrides: {
    root: {
      background: linearGradient(gradients.info.main, gradients.info.state),
      boxShadow: colored.info,

      "&.MuiPaper-root": {
        backgroundColor: transparent.main,
      },
    },
  },
};

const stepper: Types = merge(themeStyles, stepperIndexStatic.default);

export default stepper;
