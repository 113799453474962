// Base styles
import * as stepStatic from "assets/components-static-styles/components/stepper/step";

// types
type Types = any;

const step: Types = {
  ...stepStatic.default,
};

export default step;
