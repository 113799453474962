import { Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import Languages from "services/i18n/languages";

import { ReactComponent as ArrowDown } from "assets/Icons/arrowDown.svg";
import MDTypography from "components/MDTypography";
import i18n from "services/i18n/i18n";
import { useAppDispatch } from "store/hooks";
import { styles } from "./SelectLanguage.styles";

import { useMaterialUIController, setDirection } from "context";

const useStyles = makeStyles({
  button: {
    padding: "8px 16px !important",
    height: "36px !important",
    minHeight: "36px !important",
    boxShadow: "0px 2px 6px rgba(151, 172, 198, 0.25) !important",
  },
});

const SelectLanguage = () => {
  const [{ direction }, dispatch] = useMaterialUIController();
  const [menu, setMenu] = useState(null);
  const selectedLang = localStorage.getItem("language");
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const openMenu = (event: any) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const onSelect = (lang: string) => {
    if (lang !== selectedLang) {
      const langDirection: "rtl" | "ltr" = Languages.find((l) => l.key === lang)
        ?.isRtl
        ? "rtl"
        : "ltr";
      // appDispatch(updateSelectedLangauge({ selectedLanguage: lang }));
      setDirection(dispatch, langDirection);
      i18n.changeLanguage(lang);
      localStorage.setItem("language", lang);
    }
    closeMenu();
  };

  return (
    <>
      {/* <MDButton
        variant={"outlined"}
        style={{}}
        classes={{ root: classes.button }}
        onClick={openMenu}
      > */}
      <div className={classes.container} onClick={openMenu}>
        <ArrowDown />
        <MDTypography
          variant="button"
          color="black"
          marginLeft={direction === "rtl" ? "" : "8px"}
          marginRight={direction === "ltr" ? "" : "8px"}
        >
          {selectedLang}
        </MDTypography>
      </div>
      {/* </MDButton> */}
      <Menu
        anchorEl={menu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: direction === "ltr" ? "left" : "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: direction === "ltr" ? "left" : "right",
        }}
        open={Boolean(menu)}
        onClose={closeMenu}
        keepMounted
        disableAutoFocusItem
      >
        {Languages.map((lang) => (
          <MenuItem
            style={{ minWidth: "85px" }}
            key={lang.key}
            onClick={() => onSelect(lang.key)}
          >
            {lang.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default SelectLanguage;
