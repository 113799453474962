// Base styles
import * as dialogIndexStatic from "assets/components-static-styles/components/dialog/index";

import colors from "assets/theme-dark/base/colors";

import { merge } from "lodash";

const { background } = colors;

const themeStyles = {
  styleOverrides: {
    paperFullScreen: {
      backgroundColor: background.default,
    },
  },
};

const dialog = merge(themeStyles, dialogIndexStatic.default);

export default dialog;
