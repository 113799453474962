// Base styles
import * as tooltipStatic from "assets/components-static-styles/components/tooltip";
import colors from "assets/theme/base/colors";

// Helper functions
import { merge } from "lodash";

const { black, light } = colors;

// types
type Types = any;

const themeStyles = {
  styleOverrides: {
    tooltip: {
      backgroundColor: black.main,
      color: light.main,
    },

    arrow: {
      color: black.main,
    },
  },
};

const tooltip: Types = merge(themeStyles, tooltipStatic.default);

export default tooltip;
