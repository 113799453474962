export const isElVisibleInScrollableContainer = function (
  el: Element,
  container: Element
) {
  const { bottom, top } = el.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  return !(bottom <= containerRect.top || top >= containerRect.bottom);
};

export const rotateBase64Image = (
  base64data: string,
  degrees = 90
): Promise<string> => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  const image = new Image();
  // console.log("BEFORE");
  // console.log(base64data)
  image.src = base64data;
  return new Promise((resolve, reject) => {
    image.onload = () => {
      
      canvas.width = image.height;
      canvas.height = image.width;

      context.clearRect(0, 0, canvas.width, canvas.height);
      context.translate(canvas.width / 2, canvas.height / 2);
      context.rotate((degrees * Math.PI) / 180);
      context.drawImage(
        image,
        -image.width / 2,
        -image.height / 2,
        image.width,
        image.height
      );

      try {
        const rotatedBase64 = canvas.toDataURL("image/jpeg");
        // console.log("AFTER")
        // console.log(rotatedBase64)
        resolve(rotatedBase64);
      } catch (error) {
        reject(error);
      }
    };
  });
};
//
//   const imgElement = document.createElement("img");
//   imgElement.src = base64data;
//   imgElement.style.transform = `rotate(${degrees}deg)`;
//   console.log("imgElement.src");
//   console.log(imgElement.src);
//   const canvas = document.createElement("canvas");
//   const ctx = canvas.getContext("2d");
//   const image = new Image();

//   return new Promise((resolve) => {
// image.onload = function () {
//   console.log("BEFORE");
//   console.log(base64data);
//   console.log("***************");
//   console.log("***************");
//   console.log("***************");
//   console.log("***************");
//   console.log("***************");
//   console.log("***************");
//   canvas.width = degrees % 180 === 0 ? image.width : image.height;
//   canvas.height = degrees % 180 === 0 ? image.height : image.width;
//   ctx.translate(canvas.width / 2, canvas.height / 2);
//   ctx.rotate((degrees * Math.PI) / 180);
//   ctx.drawImage(image, image.width / -2, image.height / -2);
//   console.log("AFTER");
//   console.log(canvas.toDataURL());
//   console.log("***************");
//   resolve(canvas.toDataURL());
// };
// image.src = base64data;
//   });

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export const getMobileOperatingSystem = ():
  | "Windows Phone"
  | "Android"
  | "iOS"
  | "unknown" => {
  const userAgent =
    navigator.userAgent || navigator.vendor || (window as any).opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return "iOS";
  }

  return "unknown";
};
