import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import type { ApiParamsType, ApiResponseType } from "../../types/api";
import serialize from "./serialize";
import ApiMap from "./map";
import ApiException from "../../types/api";
import { getStoredAccessToken, getStoredRefreshToken, logout, storeAuthData, updateStoredAccessToken } from "utils/auth.utils";
import { AuthData } from "types/user";

class HTTP {
  organizationId = "";
  globalError = "";
  timeout = 0;

  instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_ENDPOINT}/api/v2/`,
    withCredentials: true,
  });

  public initHttpService(orgId: string) {
    this.organizationId = orgId;
  }

  public setGlobalError(error: string) {
    this.globalError = error;
  }

  public setTimeout(timeout: number) {
    this.timeout = timeout;
  }

  private getAuthHeader(token: string): { Authorization: string } | {} {
    if (token) {
      return { Authorization: `Bearer ${token}` };
    }
    return {};
  };

  private getRequestConfig({ key,
    data,
    params,
    queryParams,
    otherApiConfig,
    extraHeader,
  }: ApiParamsType): AxiosRequestConfig<any> {
    const { url, method } = otherApiConfig || ApiMap[key] || {};
    const token = getStoredAccessToken();
    return {
      headers: {
        ...this.getAuthHeader(token),
        ...(extraHeader || {})
      },
      url: serialize(url || "", params),
      timeout: this.timeout,
      method,
      params: queryParams,
      data,
    }
  }

  public async request<TResponse>(apiParams: ApiParamsType): Promise<TResponse> {
    try {
      if (ApiMap[apiParams.key].mock) {
        const res: any = {
          url: "",
          timeout: this.timeout,
          method: "get",
          params: {},
          data: ApiMap[apiParams.key].mock(),
        }
        return res.data;
      }
      const response = await this.instance<TResponse>(this.getRequestConfig(apiParams));
      return response.data;
    } catch (error: any) {
      const originalConfig = error.config;

      switch (error.response?.status) {
        case 403:
          this.logoutUser();
          break;
        case 401:
          // Access token expired - send request to refreshToken api
          if (originalConfig.url !== "/auth/login" && !originalConfig._retry) {
            originalConfig._retry = true;
            return (await this.refreshToken(originalConfig)).data;
          }
          break;

        default: {
          console.error({ error });
          throw {
            message: error.message,
            statusCode: error.status,
            status: false,
            data: error.response?.data || this.globalError,
            name: error.name,
          } as ApiException;
        }
      }
    }
  }

  private refreshTokenPendingPromise: Promise<AxiosResponse<AuthData, any>> | undefined;

  private async refreshToken(originalConfig: any): Promise<AxiosResponse<any, any>> {
    try {
      // if multiple calls to refresh token - do only one - otherwise one of them will be sent with inactive refreshToken (fix INC-237)
      if (!this.refreshTokenPendingPromise) {
        // call refreshToken api with refreshToken in header
        const refreshConfig = this.getRequestConfig({ key: "refreshToken", extraHeader: this.getAuthHeader(getStoredRefreshToken()) });
        this.refreshTokenPendingPromise = this.instance<AuthData>(refreshConfig);
      }
      const res = await this.refreshTokenPendingPromise;
      this.refreshTokenPendingPromise = undefined;
      // store the updated data
      storeAuthData(res.data);

      // recall the original request with the updated access token
      return this.instance({ ...originalConfig, headers: { ...originalConfig.headers, ...this.getAuthHeader(getStoredAccessToken()) } });
    } catch (error: any) {
      // the nest passport refresh guard - returns 401 when refresh token expired (and need to re-login)
      if (error.response?.status === 401 || error.response?.status === 403) {
        this.logoutUser();
      }
      return Promise.reject(error);
    }
  }

  private async logoutUser(): Promise<void> {
    try {
      // call logout api
      const config = this.getRequestConfig({ key: "logout" });
      await this.instance<void>(config);
      logout();
    } catch (error) {
      logout();
    }
  }
}

const http = new HTTP();
export default http;
