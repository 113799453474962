import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
  modalWrapper: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 2,
    backdropFilter: "contrast(0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  modalContent: {
    maxWidth: 320,
    width: "90vw",
    background: theme.palette.white.main,
    padding: "40px 32px 32px",
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  modalTitle: {
    fontSize: 16,
    fontWeight: 700,
    margin: "16px 0 8px",
    textAlign: "center",
  },
  modalDescription: {
    fontSize: 16,
    fontWeight: 400,
    textAlign: "center",
  },
  loader: {
    position: "relative",
  },
  loaderBody: {
    position: "absolute",
    left: "20%",
    top: "20%",
    animation: `$loaderAmimation 1000ms linear infinite`
  },
  "@keyframes loaderAmimation": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    }
  },
}));

