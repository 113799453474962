// Base styles
import * as dialogContentTextStatic from "assets/components-static-styles/components/dialog/dialogContentText";

import colors from "assets/theme/base/colors";

import { merge } from "lodash";

const { text } = colors;

const themeStyles = {
  styleOverrides: {
    root: {
      color: text.main,
    },
  },
};

const dialogContentText = merge(themeStyles, dialogContentTextStatic.default);

export default dialogContentText;
