import { AwsRum, AwsRumConfig } from 'aws-rum-web';

let awsRum: AwsRum;
try {
  const config: AwsRumConfig = {
    sessionEventLimit: 0,
    sessionSampleRate: 1,
    guestRoleArn: "arn:aws:iam::558333280333:role/RUM-Monitor-eu-west-1-558333280333-3624270748861-Unauth",
    identityPoolId: "eu-west-1:40363dcb-3e16-4868-9870-5de1e5d487e4",
    endpoint: "https://dataplane.rum.eu-west-1.amazonaws.com",
    telemetries: [],
    allowCookies: true,
    enableXRay: false
  };

  const APPLICATION_ID: string = 'e3077b90-fadf-4bc5-a69f-cd2fc203c932';
  const APPLICATION_VERSION: string = '1.0.0';
  const APPLICATION_REGION: string = 'eu-west-1';

  awsRum = new AwsRum(
    APPLICATION_ID,
    APPLICATION_VERSION,
    APPLICATION_REGION,
    config
  );
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}

export const log = (eventName: string, data: Record<string, any>): void => {
  // console.log("awsLog:" , {eventName, data})
  awsRum?.recordEvent?.(eventName, data);
}
