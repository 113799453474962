// Base styles
import * as MenuIndexStatic from "assets/components-static-styles/components/menu/index";
import colors from "assets/theme-dark/base/colors";

// Helper functions
import { merge } from "lodash";

// types
type Types = any;

const { text, background } = colors;

const themeStyles = {
  styleOverrides: {
    paper: {
      color: text.main,
      backgroundColor: `${background.card} !important`,
    },
  },
};

const menu: Types = merge(themeStyles, MenuIndexStatic.default);

export default menu;
