// Base styles
import * as popoverStatic from "assets/components-static-styles/components/popover";

// types
type Types = any;

const popover: Types = {
  ...popoverStatic.default,
};

export default popover;
