// Base styles
import * as cardMediaStatic from "assets/components-static-styles/components/card/cardMedia";

// types
type Types = any;

const cardMedia: Types = {
  ...cardMediaStatic.default,
};

export default cardMedia;
