// Base styles
import * as inputStatic from "assets/components-static-styles/components/form/input";
import colors from "assets/theme-dark/base/colors";

// Helper functions
import { merge } from "lodash";

const { inputFormBorderColor, text } = colors;

// types
type Types = any;

const themeStyles = {
  styleOverrides: {
    root: {
      color: text.primary,

      "&:hover:not(.Mui-disabled):before": {
        borderBottomColor: inputFormBorderColor,
      },

      "&:before": {
        borderColor: inputFormBorderColor,
      },

      "&:after": {
        borderColor: inputFormBorderColor,
      },
    },
  },
};

const input: Types = merge(themeStyles, inputStatic.default);

export default input;
