// Base styles
import borders from "assets/theme/base/borders";

const { borderWidth } = borders;

// types
type Types = any;

const stepConnector: Types = {
  styleOverrides: {
    root: {
      transition: "all 200ms linear",
    },

    alternativeLabel: {
      top: "14%",
      left: "-50%",
      right: "50%",
    },

    line: {
      borderWidth: `${borderWidth[2]} !important`,
      borderColor: "currentColor",
      opacity: 0.5,
    },
  },
};

export default stepConnector;
