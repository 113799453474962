// Base styles
import * as breadcrumbsStatic from "assets/components-static-styles/components/breadcrumbs";
import colors from "assets/theme/base/colors";

// Helper functions
import { merge } from "lodash";

const { grey } = colors;

// types
type Types = any;

const themeStyles = {
  styleOverrides: {
    separator: {
      color: grey[600],
    },
  },
};

const breadcrumbs: Types = merge(themeStyles, breadcrumbsStatic.default);

export default breadcrumbs;
