

import { globalColors } from "assets/colors-global";

/**
 * The base colors for the .
 * You can add new color using this file.
 * You can customized the colors for the entire  using thie file.
 */

// types
interface ColorsTypes {
  main: string;
  focus: string;
}

interface GradientsTypes {
  main: string;
  state: string;
}

interface SocialMediaColorsTypes {
  main: string;
  dark: string;
}

interface BadgeColorsTypes {
  background: string;
  text: string;
}

interface Types {
  extraColors: any;
  cardTitleContrast: { main: string };
  background:
  | {
    default: string;
    sidenav?: string;
    card?: string;
  }
  | any;
  white:
  | {
    main: string;
    focus: string;
  }
  | any;
  text:
  | {
    main: string;
    focus: string;
    primary?: string;
    secondary?: string;
    disabled?: string;
  }
  | any;
  transparent:
  | {
    main: string;
  }
  | any;
  black:
  | {
    light: string;
    main: string;
    focus: string;
  }
  | any;
  primary: ColorsTypes | any;
  secondary: ColorsTypes | any;
  info: ColorsTypes | any;
  success: ColorsTypes | any;
  warning: ColorsTypes | any;
  error: ColorsTypes | any;
  light: ColorsTypes | any;
  dark: ColorsTypes | any;
  contrast: ColorsTypes | any;
  lightBlue: ColorsTypes | any;
  grey:
  | {
    [key: string | number]: string;
  }
  | any;
  gradients:
  | {
    primary: GradientsTypes;
    secondary: GradientsTypes;
    info: GradientsTypes;
    success: GradientsTypes;
    warning: GradientsTypes;
    error: GradientsTypes;
    light: GradientsTypes;
    dark: GradientsTypes;
  }
  | any;
  socialMediaColors:
  | {
    facebook: SocialMediaColorsTypes;
    twitter: SocialMediaColorsTypes;
    instagram: SocialMediaColorsTypes;
    linkedin: SocialMediaColorsTypes;
    pinterest: SocialMediaColorsTypes;
    youtube: SocialMediaColorsTypes;
    vimeo: SocialMediaColorsTypes;
    slack: SocialMediaColorsTypes;
    dribbble: SocialMediaColorsTypes;
    github: SocialMediaColorsTypes;
    reddit: SocialMediaColorsTypes;
    tumblr: SocialMediaColorsTypes;
  }
  | any;
  badgeColors:
  | {
    primary: BadgeColorsTypes;
    secondary: BadgeColorsTypes;
    info: BadgeColorsTypes;
    success: BadgeColorsTypes;
    warning: BadgeColorsTypes;
    error: BadgeColorsTypes;
    light: BadgeColorsTypes;
    dark: BadgeColorsTypes;
  }
  | any;
  coloredShadows:
  | {
    [key: string]: string;
  }
  | any;
  inputBorderColor: string;
  inputFormBorderColor: string;
  tabs:
  | {
    indicator:
    | {
      boxShadow: string;
    }
    | any;
  }
  | any;
}

const colors: Types = {
  extraColors: {
    circleGrey: "#E6EDED",
    lightGreyBorder: "#E9EAED",
  },
  background: {
    default: "#F5F5F5",
    primary: globalColors.primary4.main,
    secondary: globalColors.secondary5.main,
    paper: globalColors.primary1.main,
  },

  text: {
    main: globalColors.secondary1.main,
    focus: globalColors.secondary1.main,
    header: "#364868",
    primary: globalColors.secondary1.main,
    secondary: globalColors.secondary3.main,
    contrast: globalColors.primary3.main,
    error: globalColors.secondary6.main,
  },

  transparent: {
    main: "transparent",
  },

  white: {
    main: "#ffffff",
    focus: "#ffffff",
  },

  black: {
    light: "#000000",
    main: globalColors.secondary1.main,
    focus: "#000000",
  },

  primary: {
    main: globalColors.primary1.main,
    contrastText: globalColors.primary3.main,
    disabled: globalColors.primary3.main,
  },
  contrast: {
    main: globalColors.darkPass.main,
    focus: globalColors.darkPass.main,
  },
  secondary: {
    main: globalColors.primary2.main,
  },
  info: {
    main: globalColors.secondary4.main,
    state: globalColors.secondary4.main,
    contrastText: globalColors.primary2.main,
  },

  success: {
    main: globalColors.secondary7.main,
    focus: globalColors.success.main,
  },

  warning: {
    main: "#fb8c00",
    focus: globalColors.warning.main,
  },

  error: {
    main: "#F44335",
    focus: globalColors.error.main,
  },

  light: {
    main: "#F5F5F5",
    focus: "#F5F5F5",
  },

  dark: {
    main: globalColors.darkPass.main,
    focus: globalColors.darkPass.main,
  },
  lightBlue: {
    main: "#439DEE",
    focus: "#439DEE",
  },
  grey: {
    100: "#f8f9fa",
    200: "#F5F5F5",
    300: "#D9D9D9",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#6c757d",
    700: "#495057",
    800: "#343a40",
    900: "#212529",
  },

  gradients: {
    primary: {
      main: globalColors.primary1.main,
      state: globalColors.primary2.main,
    },

    secondary: {
      main: "#3E3D45",
      state: "#202020",
    },

    info: {
      main: globalColors.secondary3.main,
      state: globalColors.secondary3.main,
    },

    success: {
      main: "#66BB6A",
      state: "#43A047",
    },

    warning: {
      main: "#FFA726",
      state: "#FB8C00",
    },

    error: {
      main: "#EF5350",
      state: "#E53935",
    },

    light: {
      main: "#EBEFF4",
      state: "#CED4DA",
    },

    dark: {
      main: globalColors.secondary2.main,
      state: "#191919",
    },

    greenButton: {
      main: "#32AA64",
      state: "#84f7b4",
      dark: "#003C00",
    },
  },

  socialMediaColors: {
    facebook: {
      main: "#3b5998",
      dark: "#344e86",
    },

    twitter: {
      main: "#55acee",
      dark: "#3ea1ec",
    },

    instagram: {
      main: "#125688",
      dark: "#0e456d",
    },

    linkedin: {
      main: "#0077b5",
      dark: "#00669c",
    },

    pinterest: {
      main: "#cc2127",
      dark: "#b21d22",
    },

    youtube: {
      main: "#e52d27",
      dark: "#d41f1a",
    },

    vimeo: {
      main: "#1ab7ea",
      dark: "#13a3d2",
    },

    slack: {
      main: "#3aaf85",
      dark: "#329874",
    },

    dribbble: {
      main: "#ea4c89",
      dark: "#e73177",
    },

    github: {
      main: "#24292e",
      dark: "#171a1d",
    },

    reddit: {
      main: "#ff4500",
      dark: "#e03d00",
    },

    tumblr: {
      main: "#35465c",
      dark: "#2a3749",
    },
  },

  badgeColors: {
    primary: {
      background: "#f8b3ca",
      text: "#cc084b",
    },

    secondary: {
      background: "#d7d9e1",
      text: "#6c757d",
    },

    info: {
      background: globalColors.secondary3.main + "20",
      text: globalColors.secondary3.main,
    },

    success: {
      background: globalColors.secondary7.main + "20",
      text: globalColors.secondary7.main,
    },

    warning: {
      background: "#ffd59f",
      text: "#c87000",
    },

    error: {
      background: globalColors.secondary6.main + "20",
      text: globalColors.secondary6.main,
    },

    light: {
      background: "#ffffff",
      text: "#c7d3de",
    },

    dark: {
      background: "#8097bf",
      text: "#1e2e4a",
    },
  },

  coloredShadows: {
    primary: "#e91e62",
    secondary: "#110e0e",
    info: globalColors.secondary3.main,
    success: "#4caf4f",
    warning: "#ff9900",
    error: "#f44336",
    light: "#adb5bd",
    dark: "#404040",
  },

  inputBorderColor: "#8C8F9F",
  inputFormBorderColor: "#C7CCD0",
  cardTitleContrast: { main: globalColors.secondary2.main },

  tabs: {
    indicator: { boxShadow: "#ddd" },
  },
};

export default colors;
