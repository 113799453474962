// Base styles
import * as dialogContentStatic from "assets/components-static-styles/components/dialog/dialogContent";
import colors from "assets/theme-dark/base/colors";
import borders from "assets/theme-dark/base/borders";

import { merge } from "lodash";

const { text } = colors;
const { borderWidth, borderColor } = borders;

// types
const themeStyles = {
  styleOverrides: {
    root: {
      color: text.main,
    },

    dividers: {
      borderTop: `${borderWidth[1]} solid ${borderColor}`,
      borderBottom: `${borderWidth[1]} solid ${borderColor}`,
    },
  },
};

const dialogContent = merge(themeStyles, dialogContentStatic.default);

export default dialogContent;
