import colors from "assets/theme/base/colors";

const { text, white, transparent, light, background, primary } = colors;

interface Styles {
  [key: string]: any;
}
export const styles: Styles = {
  actionComponentContainer: {
    height: "418px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    background: white.main,
    borderRadius: "28px",
    boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.25)",
    padding: "44px 22px",
  },
  actionBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  image: {
    marginBottom: "25px",
  },
  title: {
    fontWeight: 700,
    fontSize: "18px",
  },
  buttonsSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  text: {
    marginTop: 11,
  },
  twoButtons: {
    justifyContent: "space-between",
  },
};
