import colors from "assets/theme/base/colors";

const { text, white, transparent, light, background, primary } = colors;

interface Styles {
  [key: string]: any;
}
export const styles: Styles = {
  bodyContainer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "calc(100vh - 190px)",
    background: `${white.main}!important`,
    color: text.main,
    borderTopLeftRadius: "28px",
    borderTopRightRadius: "28px",
    padding: "28px 28px 36px 28px",
    overflow: "hidden",
  },
  step_MODALS: {
    background: `${background.primary}!important`,
    bottom: 0,
    width: "100%",
    height: "calc(100vh - 120px)",
    borderRadius: 0,
  },
};
