// Base styles
import * as inputOutlinedStatic from "assets/components-static-styles/components/form/inputOutlined";
import colors from "assets/theme-dark/base/colors";

// Helper functions
import { merge } from "lodash";
import rgba from "assets/theme-dark/functions/rgba";

const { inputFormBorderColor, transparent, info, white, grey } = colors;

// types
type Types = any;

const themeStyles = {
  styleOverrides: {
    root: {
      backgroundColor: transparent.main,

      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: rgba(inputFormBorderColor, 0.6),
      },
      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: info.main,
        },
      },
    },

    notchedOutline: {
      borderColor: rgba(inputFormBorderColor, 0.6),
    },

    input: {
      color: white.main,
      backgroundColor: transparent.main,

      "&::-webkit-input-placeholder": {
        color: grey[100],
      },
    },

    multiline: {
      color: grey[700],
    },
  },
};

const inputOutlined: Types = merge(themeStyles, inputOutlinedStatic.default);

export default inputOutlined;
