// Base styles
import * as textFieldStatic from "assets/components-static-styles/components/form/textField";

// types
type Types = any;

const textField: Types = {
  ...textFieldStatic.default,
};

export default textField;
