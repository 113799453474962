import { toast, ToastOptions } from "react-toastify";

export const toastifyError = (message: string, options: ToastOptions = {}) => {
  toast.error(message, {
    autoClose: 3000,
    hideProgressBar: true,
    ...options,
  });
};

export const toastifySuccess = (
  message: string,
  options: ToastOptions = {}
) => {
  toast.success(message, {
    autoClose: 3000,
    hideProgressBar: true,
    ...options,
  });
};
