import colors from "assets/theme/base/colors";

const { text, white, transparent, light, background, primary } = colors;

interface Styles {
  [key: string]: any;
}
export const styles: Styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  resaultComponentContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center"
  },
  resaultBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "65px"
  },
  image: {
    marginBottom: "30px",
    marginRight: "10px"
  },
  title: {
    fontWeight: 700,
    fontSize: "18px",
    marginBottom: "8px"
  },
  text: {
    fontSize: "14px"
  },
  buttonsSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%"
  },
  link: {
    marginBottom: "40px",
    fontWeight: 700,
    fontSize: "14px",
    color: primary.main
  },
  modalResaultContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "42px 22px"
  }
};
