// Base styles
import * as inputLabelStatic from "assets/components-static-styles/components/form/inputLabel";
import colors from "assets/theme/base/colors";

// Helper functions
import { merge } from "lodash";

const { text } = colors;

// types
type Types = any;

const themeStyles = {
  styleOverrides: {
    root: {
      color: text.main,

      "&.Mui-focused": {
        color: text.main,
      },
    },
  },
};

const inputLabel: Types = merge(themeStyles, inputLabelStatic.default);

export default inputLabel;
