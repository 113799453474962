
import { createContext, ReactNode, useCallback, useContext, useRef, useState } from 'react'
import ConfirmDialog from './confirmDialog'

type DialogContextType = (data: DialogOptions) => Promise<boolean>

class DialogContextProviderProps {
  children: ReactNode
}

export enum ConfirmDialogTypes {
  ALERT,
  WARNING,
  INFORMATION,
  CUSTOM
}

export class DialogOptions {
  isOpen?: boolean
  type?: ConfirmDialogTypes
  header?: string | JSX.Element
  body?: string | JSX.Element
  okLabel?: string
  cancelLabel?: string
}

export const ConfirmDialogContext = createContext<DialogContextType>({} as DialogContextType)

export function ConfirmDialogProvider({ children }: DialogContextProviderProps) {
  const [state, setState] = useState<DialogOptions>({ isOpen: false })
  const fn = useRef<(choice: boolean) => void>((choice: boolean) => { })

  const confirm = useCallback(
    (data: DialogOptions) => {
      return new Promise<boolean>(resolve => {
        setState({ type: ConfirmDialogTypes.ALERT, ...data, isOpen: true })
        fn.current = (choice: boolean) => {
          setState({ isOpen: false })
          resolve(choice)
        }
      })
    },
    [setState]
  )

  return (
    <ConfirmDialogContext.Provider value={confirm}>
      {children}
      <ConfirmDialog
        {...state}
        onClose={() => fn.current(false)}
        onConfirm={() => fn.current(true)}
        isOpen={state.isOpen || false}
      />
    </ConfirmDialogContext.Provider>
  )
}

export default function useConfirm() {
  return useContext(ConfirmDialogContext)
}
